import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import type {
  DomainListingRequest,
  DomainListingResponse,
  DomainPropertyRequest,
  DomainPropertyResponse,
  DomainPropertySuggestionRequest,
  DomainPropertySuggestionResponse,
} from '@home-in/models'

export const domainApi = createApi({
  reducerPath: 'domainApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_BASE_API}domain`,
  }),
  tagTypes: ['Property', 'Listing'],
  endpoints: (builder) => ({
    getProperty: builder.query<DomainPropertyResponse, DomainPropertyRequest>({
      query: (body) => {
        return {
          url: `/v1/properties/${body}`,
          method: 'GET',
        }
      },
      providesTags: ['Property'],
    }),
    getListing: builder.query<DomainListingResponse, DomainListingRequest>({
      query: (body) => {
        return {
          url: `/v1/listings/${body}`,
          method: 'GET',
        }
      },
      providesTags: ['Listing'],
    }),
    suggestProperties: builder.query<
      DomainPropertySuggestionResponse,
      DomainPropertySuggestionRequest & { time: string }
    >({
      queryFn: async (
        { terms, pageSize = 20, channel = 'Residential' }: DomainPropertySuggestionRequest,
        _queryApi,
        _extraOptions,
        baseQuery
      ) => {
        // Return an empty array or default response when terms is an empty string
        if (!terms.trim()) {
          return { data: [] as DomainPropertySuggestionResponse }
        }

        // Call the API if terms is not empty
        const result = await baseQuery({
          url: `/v1/properties/_suggest?terms=${terms}&pageSize=${pageSize}&channel=${channel}`,
          method: 'GET',
        })

        if (result.error) {
          return { error: result.error }
        }
        return { data: result.data as DomainPropertySuggestionResponse }
      },
    }),
  }),
})

export const { useLazyGetPropertyQuery, useLazyGetListingQuery, useLazySuggestPropertiesQuery } = domainApi
