import React, { HTMLAttributes, MouseEventHandler } from 'react'
import SVG from 'react-inlinesvg'

import { TeamIcons } from '@features/home/parts/team'
import { classNames } from '@utils/helpers/classNameHelper'
import { Illustrations } from './illustration'

interface IconProps extends HTMLAttributes<HTMLElement> {
  asset: Icons | TeamIcons | Illustrations
  size?: number
  rounded?: boolean
}

interface IconButtonProps extends IconProps {
  callback?: MouseEventHandler
  hoverEffect?: boolean
  text?: string
}

export enum Icons {
  add = '/icons/add.svg',
  arrowDropDown = '/icons/arrow-drop-down.svg',
  attach = '/icons/attach.svg',
  arrowBack = '/icons/arrow-back.svg',
  cash = '/icons/cash.svg',
  briefcase = '/icons/briefcase.svg',
  call = '/icons/call.svg',
  cancel = '/icons/cancel.svg',
  cbaLogo = '/logos/CBA.svg',
  cbaLogoWithText = '/logos/CBAWithText.svg',
  checkmark = '/icons/checkmark.svg',
  checkmarkCircle = '/icons/checkmark-circle.svg',
  inProgressCircle = '/icons/in-progress-circle.svg',
  chevronRight = '/icons/chevron-right.svg',
  download = '/icons/download.svg',
  edit = '/icons/edit.svg',
  envelope = '/icons/envelope.svg',
  place = '/icons/place.svg',
  quote = '/icons/quote.svg',
  search = '/icons/search.svg',
  send = '/icons/send.svg',
  sort = '/icons/sort.svg',
  star = '/icons/star.svg',
  thumbsDown = '/icons/thumbs-down.svg',
  thumbsUp = '/icons/thumbs-up.svg',
  trash = '/icons/trash.svg',
  user = '/icons/user-icon.svg',
  warning = '/icons/warning.svg',
  refresh = '/icons/refresh.svg',
  key = '/icons/key.svg',
  identification = 'icons/identification.svg',
}

export const Icon = ({ className, asset, size = 16, rounded = false }: IconProps) => (
  <div className={classNames(className)} style={{ width: size + 'px', height: size + 'px' }}>
    <SVG className={`h-full w-full ${rounded && 'rounded-full'}`} src={asset} />
  </div>
)

export const IconButton = ({ className, asset, size = 16, hoverEffect = true, callback, text }: IconButtonProps) => {
  const label = asset.replace('/icons/', '').replace('.svg', '')

  return (
    <button
      onClick={callback}
      type="button"
      className={classNames(
        hoverEffect && 'hover:bg-muted-200/25',
        'box-content flex justify-center items-center p-2.5 transition-all focus:bg-muted-200/25 active:bg-muted-200/50 rounded-full',
        className
      )}
      aria-label={label}
    >
      <Icon asset={asset} size={size} />
      {text && <div className="ml-3 font-bold text-secondary">{text}</div>}
    </button>
  )
}
