import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WaratahPropertyWithChecklist } from '@features/checklist/data/checklist-data-types'

export enum TaskUploadStatus {
  Success = 'success',
  Error = 'error',
  Pending = 'pending',
  Uploading = 'uploading',
  Selected = 'selected',
  Uninitialised = 'uninitialised',
}

interface TaskUpload {
  taskRef: string
  status: TaskUploadStatus
}

interface ChecklistState {
  checklists: WaratahPropertyWithChecklist[] | null
  currentChecklist: WaratahPropertyWithChecklist | null
  openCategories: Record<string, boolean>
  removedChecklists: WaratahPropertyWithChecklist[]
  taskUploads: TaskUpload[]
  checklistPendingUpdates: boolean
}

export const checklist = createSlice({
  name: 'checklist',
  initialState: <ChecklistState>{
    checklists: null,
    currentChecklist: null,
    openCategories: {},
    removedChecklists: [],
    taskUploads: [],
    checklistPendingUpdates: false,
  },
  reducers: {
    addCategory: (state, action: PayloadAction<string>) => {
      state.openCategories = {
        ...state.openCategories,
        [action.payload]: state.openCategories[action.payload] || false,
      }
    },
    resetCategories: (state) => {
      state.openCategories = {}
    },
    toggleActiveCategory: (state, action: PayloadAction<string>) => {
      state.openCategories = { ...state.openCategories, [action.payload]: !state.openCategories[action.payload] }
    },
    setChecklistPendingUpdates: (state, action: PayloadAction<boolean>) => {
      state.checklistPendingUpdates = action.payload
    },
    setChecklists: (state, action: PayloadAction<WaratahPropertyWithChecklist[]>) => {
      state.checklists = action.payload
    },
    setCurrentChecklist: (state, action: PayloadAction<WaratahPropertyWithChecklist | null>) => {
      state.currentChecklist = action.payload
    },
    addRemovedProperty: (state, action: PayloadAction<WaratahPropertyWithChecklist>) => {
      if (!state.removedChecklists) {
        state.removedChecklists = [action.payload]
      } else {
        state.removedChecklists = [action.payload, ...state.removedChecklists]
      }
    },
    addOrUpdateTaskUpload: (state, action: PayloadAction<TaskUpload>) => {
      state.taskUploads = [
        ...state.taskUploads.filter((taskUpload) => taskUpload.taskRef !== action.payload.taskRef),
        action.payload,
      ]
    },
    removeTaskUpload: (state, action: PayloadAction<string>) => {
      state.taskUploads = state.taskUploads.filter((taskUpload) => taskUpload.taskRef !== action.payload)
    },
  },
})

export const {
  addCategory,
  resetCategories,
  toggleActiveCategory,
  setChecklistPendingUpdates,
  // Waratah reducers
  setChecklists,
  addRemovedProperty,
  setCurrentChecklist,
  // async task management
  addOrUpdateTaskUpload,
  removeTaskUpload,
} = checklist.actions
export default checklist.reducer
