import {
  CompleteUploadRequestType,
  CompleteUploadResponseType,
  UploadDocumentRequestType,
  UploadDocumentResponseType,
  DownloadDocumentResponseType,
  GetDocumentListResponse,
  GenericFilesUploadRequest,
} from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithHeaders } from './custom-base-query'

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: baseQueryWithHeaders('camel/v1/'),
  tagTypes: ['Documents'],
  endpoints: (builder) => ({
    getSignedUrl: builder.mutation<string, UploadDocumentRequestType>({
      query: (body) => ({ url: 'documents/s3', method: 'POST', body }),
      transformResponse: (response: { data: UploadDocumentResponseType }) => response?.data?.preSignedUrl,
    }),
    getDocumentList: builder.query<GetDocumentListResponse, string>({
      query: (body) => `documents/list?HBJKey=${body}`,
      providesTags: ['Documents'],
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: GetDocumentListResponse }) => response.data,
    }),
    registerDocumentWithPega: builder.mutation<CompleteUploadResponseType, CompleteUploadRequestType>({
      query: (body) => ({ url: 'documents/pega', method: 'POST', body }),
      invalidatesTags: ['Documents'],
    }),
    getDocumentSignedUrl: builder.mutation<string, string>({
      query: (body) => body,
      transformResponse: (response: { data: DownloadDocumentResponseType }) => response?.data?.preSignedUrl,
    }),
    registerGenericDocumentsWithPega: builder.mutation<null, GenericFilesUploadRequest>({
      query: (body) => ({ url: 'documents/files', method: 'POST', body }),
      invalidatesTags: ['Documents'],
    }),

    getDocumentListByPropertyId: builder.query<GetDocumentListResponse, string>({
      query: (body) => `documents/list-by-property-id?propertyId=${body}`,
      providesTags: ['Documents'],
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: GetDocumentListResponse }) => response.data,
    }),
  }),
})

export const {
  useGetSignedUrlMutation,
  useRegisterDocumentWithPegaMutation,
  useGetDocumentListQuery,
  useGetDocumentSignedUrlMutation,
  useRegisterGenericDocumentsWithPegaMutation,
  useGetDocumentListByPropertyIdQuery,
} = documentsApi
