import {
  MuscovyAddPropertyRequestSchemaType,
  MuscovyGetPropertyResponseSchemaType,
  MuscovyPropertySchemaType,
} from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/query/react'
import { addRemovedSellProperty, deleteRemovedSellProperty } from '@redux/reducers/sell'
import { trackEventsInApiCall } from '@redux/utils/analytics'
import { AnalyticsCategories, AnalyticsEventAPICallEventNames } from '@utils/helpers/analytics.enum'
import { baseQueryWithHeaders } from './custom-base-query'

const checklistCacheTags = ['Properties'] as const

// Muscovy: Sell Property API
// https://home-in.atlassian.net/wiki/spaces/EN/pages/506363961
export const sellPropertyApi = createApi({
  reducerPath: 'sellPropertyApi',
  baseQuery: baseQueryWithHeaders('muscovy/v1/'),
  tagTypes: checklistCacheTags,
  endpoints: (builder) => ({
    addSellProperty: builder.mutation<MuscovyPropertySchemaType, MuscovyAddPropertyRequestSchemaType>({
      query: (body) => ({ url: 'property', method: 'POST', body }),
      transformResponse: (response: { data: MuscovyPropertySchemaType }) => response?.data,
      // invalidatesTags: ['Properties'],
      // We don't invalidate the 'Properties' tag here, we do this in addSellChecklist to avoid
      // prematurely refreshing the page when adding a sell property
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.AddSellProperty,
            category: AnalyticsCategories.Property,
          },
        })
        await queryFulfilled
      },
    }),

    getSellProperties: builder.query<MuscovyPropertySchemaType[], void>({
      query: () => ({ url: 'property/', method: 'GET' }),
      keepUnusedDataFor: 5,
      providesTags: ['Properties'],
      transformResponse: (response: { data: MuscovyGetPropertyResponseSchemaType }) => {
        if (!response?.data || response?.data?.properties.length < 1) {
          return []
        }
        return response.data.properties
      },
    }),
    archiveSellProperty: builder.mutation<{}, { propertyId: string }>({
      query: (body) => ({ url: `archive-property`, method: 'PATCH', body }),
      transformResponse: (response: { data: MuscovyPropertySchemaType }) => response?.data,
      invalidatesTags: ['Properties'],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        dispatch(addRemovedSellProperty(props.propertyId))
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.RemoveSellProperty,
            category: AnalyticsCategories.Property,
          },
          undo: () => {
            dispatch(deleteRemovedSellProperty(props.propertyId))
          },
        })
        await queryFulfilled
      },
    }),
  }),
})

export const { useAddSellPropertyMutation, useGetSellPropertiesQuery, useArchiveSellPropertyMutation } = sellPropertyApi
