import {
  GetHomeBuyingJourneyHbj,
  GetHomeBuyingJourneyResponse,
  GetHomeBuyingJourneyWorkPartyHbj,
  GetHomeBuyingJourneyWorkPartyResponse,
  TrueFalse,
} from '@home-in/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface HomeState {
  openTab: number
  returnToTab: number | null
  hbj: GetHomeBuyingJourneyHbj[]
  activeHbj?: GetHomeBuyingJourneyHbj
  workParty: { team: GetHomeBuyingJourneyWorkPartyHbj[]; loaded: boolean }
  loaded: boolean
}

const initialState: HomeState = {
  openTab: 1,
  returnToTab: null,
  hbj: [],
  loaded: false,
  workParty: { team: [], loaded: false },
}

export const home = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setOpenTab: (state, action) => {
      state.openTab = action.payload
    },
    setReturnToTab: (state, action) => {
      state.returnToTab = action.payload
    },
    clearReturnToTab: (state) => {
      state.returnToTab = null
    },
    setHomeBuyingJourneys: (state, action: PayloadAction<GetHomeBuyingJourneyResponse>) => {
      state.hbj = action.payload.hbj
      state.loaded = true
    },
    setActiveHomeBuyingJourney: (state, action: PayloadAction<string | undefined>) => {
      const activeHbj = state.hbj.find((hbj) => hbj.hbj_id === action.payload)
      if (activeHbj) {
        state.activeHbj = activeHbj
      }
    },
    setWorkParty: (state, action: PayloadAction<GetHomeBuyingJourneyWorkPartyResponse>) => {
      if (action.payload.work_party.length > 0) {
        state.workParty = { team: action.payload.work_party, loaded: true }
      }
    },
    setWorkPartyLoaded: (state, action: PayloadAction<boolean>) => {
      state.workParty.loaded = action.payload
    },
    acceptAgreedConveyancingCostsOptimistically: (state) => {
      if (state.activeHbj) {
        state.activeHbj = { ...state.activeHbj, has_agreed_conveyancing_costs: TrueFalse.True }
      }
    },
  },
})

export const {
  setOpenTab,
  setReturnToTab,
  clearReturnToTab,
  setHomeBuyingJourneys,
  setActiveHomeBuyingJourney,
  setWorkParty,
  setWorkPartyLoaded,
  acceptAgreedConveyancingCostsOptimistically,
} = home.actions
export default home.reducer
