import { useAppSelector } from '@redux/hooks'
import { useCurrentChecklist } from './useCurrentChecklist'

const useActiveHbj = () => {
  const activeHbjId = useAppSelector((state) => state.home.activeHbj?.hbj_id)

  const currentChecklist = useCurrentChecklist()
  const activeHbjIdUsingChecklist = currentChecklist?.hbjId || activeHbjId
  const activeHbjUsingChecklist = useAppSelector((state) =>
    state.home.hbj.find((hbj) => hbj.hbj_id === activeHbjIdUsingChecklist)
  )

  return { activeHbj: activeHbjUsingChecklist, activeHbjId: activeHbjIdUsingChecklist }
}

export default useActiveHbj
