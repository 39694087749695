import {} from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { fireToastNotifications } from '@redux/reducers/notifications'
import { trackEventsInApiCall } from '@redux/utils/analytics'
import { AnalyticsCategories, AnalyticsEventAPICallEventNames } from '@utils/helpers/analytics.enum'
import { baseQueryWithHeaders } from './custom-base-query'

const bankDetailsCacheTags = ['BankDetails'] as const

export const sellBankDetailsApi = createApi({
  reducerPath: 'sellBankDetailsApi',
  baseQuery: baseQueryWithHeaders('panther/v1/'),
  tagTypes: bankDetailsCacheTags,
  endpoints: (builder) => ({
    addBankDetails: builder.mutation<
      any,
      { propertyId: string; accountNumber: string; bsbNumber: string; accountName: string }
    >({
      query: (body) => {
        return { url: 'customer/account', method: 'POST', body }
      },
      invalidatesTags: ['BankDetails'],
      transformResponse: (response: any) => response,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.UpdateBankDetails,
            category: AnalyticsCategories.Checklist,
          },
          fulfilled: () => {
            dispatch(
              fireToastNotifications({
                result: new Promise((resolve) => resolve('')),
                successMessage: 'Bank details have been updated',
              })
            )
          },

          undo: () => {
            dispatch(
              fireToastNotifications({
                result: new Promise((_, reject) => reject()),
                errorMessage: `Failed to update your bank details`,
              })
            )
          },
        })

        await queryFulfilled
      },
    }),
    getBankDetails: builder.query<
      { propertyId: string; accountNumber: string; bsbNumber: string; accountName: string },
      { propertyId: string }
    >({
      query: ({ propertyId }) => ({ url: `customer/account/${propertyId}`, method: 'GET' }),
      keepUnusedDataFor: 0,
      providesTags: ['BankDetails'],
    }),
  }),
})

export const { useAddBankDetailsMutation, useGetBankDetailsQuery } = sellBankDetailsApi
