import { LenderProfile } from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithHeaders } from './custom-base-query'

const lenderProfileApiCacheTags = ['LenderProfile'] as const

export type LenderState = LenderProfile & { intercomClosed: boolean }

export const lenderProfileApi = createApi({
  reducerPath: 'lenderProfileApi',
  baseQuery: baseQueryWithHeaders('hamster'),
  tagTypes: lenderProfileApiCacheTags,
  endpoints: (builder) => ({
    getLenderProfile: builder.query<LenderState, void>({
      query: () => {
        return {
          url: 'v1/lenderprofile',
        }
      },
      transformResponse: (response: { data: LenderProfile }) => ({ ...response?.data, intercomClosed: false }),
      providesTags: ['LenderProfile'],
    }),
  }),
})

export const { useGetLenderProfileQuery } = lenderProfileApi
