import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Themes } from '@theme/constants'

export interface LDFeatureFlag {
  guidanceIndex: boolean
  killswitch: boolean
  showAuspostForListedStates: string[]
  showLivesignForListedStates: string[]
  showZipIdForListedStates: string[]
  staff: boolean
  supportInbox: boolean
  intercom: boolean
  checklistTaskBlacklist: string[]
  newDocumentUpload: boolean
  contractsEmailInbox: boolean
  newWelcomeUi: boolean
  conveyancingCostDate: string
  newVoiCopy: boolean
  pegaUrlV2: boolean
  enableWaratahChecklist: boolean
  enableTaskS3Download: boolean
  displayDynamicPrice: boolean
  npsModal: boolean
  skipTermsAndConditions: boolean
  displayAdSourceDropdown: boolean
  enableMaterialUiForms: boolean
  sellSideAddProperty: boolean
  uxChangesFromSellSide: boolean
  preSignupFlowUtmIdAwareCarousel: boolean
}

export interface AppState {
  platform: string
  flags: LDFeatureFlag
  theme: keyof typeof Themes
  continueOnboardingAfterAcceptingTerms: boolean
  utmId: string
}

export const app = createSlice({
  name: 'app',
  initialState: <AppState>{
    flags: {},
    continueOnboardingAfterAcceptingTerms: false,
    theme: 'DefaultTheme',
  },
  reducers: {
    setTheme: (state, action: PayloadAction<AppState['theme']>) => {
      state.theme = action.payload
    },
    setFlags: (state, action: PayloadAction<Record<string, any>>) => {
      state.flags = { ...state.flags, ...action.payload }
    },
    setContinueOnboardingAfterAcceptingTerms: (state, action: PayloadAction<boolean>) => {
      state.continueOnboardingAfterAcceptingTerms = action.payload
    },
    setUtmId: (state, action: PayloadAction<string>) => {
      state.utmId = action.payload
    },
  },
})

export const { setFlags, setContinueOnboardingAfterAcceptingTerms, setUtmId } = app.actions
export default app.reducer
