import { ErrorSeverity } from '@home-in/models'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import { RootState } from '@redux/store'
import { sendClientLog } from '@utils/helpers/error.helpers'
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // Only run in client
  if (isRejectedWithValue(action) && typeof window !== 'undefined') {
    const { auth, page } = api.getState() as RootState
    sendClientLog({
      auth0id: auth?.user?.sub,
      host: window?.location?.host,
      message: `${action.payload?.status || 'No status'}: ${
        action.payload?.data?.message || action.payload?.error || action.error?.message || 'No error message'
      }`,
      route: page?.route,
      severity: !action.payload.status
        ? ErrorSeverity.ERROR
        : action.payload.status >= 500
        ? ErrorSeverity.ERROR
        : ErrorSeverity.WARNING,
      stackTrace: `RTK Query Failure: ${action.meta?.baseQueryMeta?.request?.method || 'no method'} ${
        action.meta?.baseQueryMeta?.request?.url || 'no URL'
      }`,
    })
  }

  return next(action)
}
