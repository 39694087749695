import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useAppSelector } from '@redux/hooks'

/**
 * This hook initializes the LaunchDarkly client with the lender's buying assistant and conveyancer information
 */
const useLenderLaunchDarklySetup = () => {
  const launchDarkly = useLDClient()
  const user = useAppSelector((state) => state.auth.user)
  const lenderProfile = useAppSelector((state) => state.lenderProfile)

  useEffect(() => {
    // Update the user's LaunchDarkly context with BA and Conveyancing firm information
    const buyingAssistantName = `${lenderProfile?.buyingAssistant?.firstName} ${lenderProfile.buyingAssistant?.lastName}`

    if (!!user?.sub && !!buyingAssistantName) {
      launchDarkly?.identify({
        kind: 'user',
        key: user?.sub,
        email: lenderProfile?.email,
        buyingAssistant: buyingAssistantName || '',
      })
    }
  }, [user?.sub, lenderProfile?.buyingAssistant])
}

export default useLenderLaunchDarklySetup
