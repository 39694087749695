import { GetTokenSilentlyOptions, GetIdTokenClaimsOptions } from '@auth0/auth0-react'

export type GetAccessTokenSilentlyFunc = (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
export type GetIdTokenClaimsFunc = (options?: GetIdTokenClaimsOptions | undefined) => Promise<any>

let getAccessTokenSilentlyFunc: GetAccessTokenSilentlyFunc
let getIdTokenClaimsFunc: GetIdTokenClaimsFunc

export const jwt = {
  getAccessTokenSilently: () => getAccessTokenSilentlyFunc,
  setAccessTokenSilently: (func: GetAccessTokenSilentlyFunc) => (getAccessTokenSilentlyFunc = func),
  getIdTokenClaims: () => getIdTokenClaimsFunc,
  setIdTokenClaims: (func: GetIdTokenClaimsFunc) => (getIdTokenClaimsFunc = func),
}
