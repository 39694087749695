import React from 'react'
import { LinkStyled } from './forms/link'

const footerContent = [
  <>Home-in is a brand of CBA New Digital Businesses Pty Ltd ABN 38 633 072 830 trading as Home-in Digital.</>,
  <>
    CBA New Digital Businesses Pty Ltd is a wholly owned but non-guaranteed subsidiary of the Commonwealth Bank of
    Australia ABN 48 123 123 124.
  </>,
  <>
    CBA New Digital Businesses Pty Ltd is not an Authorised Deposit-taking Institution for the purposes of the Banking
    Act 1959 and its obligations do not represent deposits or other liabilities of Commonwealth Bank of Australia.To
    access detailed terms and conditions about this website and our app please{' '}
    <LinkStyled href="https://www.home-in.com.au/terms-and-conditions" target="_blank">
      click here.
    </LinkStyled>{' '}
    For information on how we collect and handle personal information, see our{' '}
    <LinkStyled href="https://www.home-in.com.au/privacy" target="_blank">
      Privacy Policy
    </LinkStyled>
    .
  </>,
]

export const Footer = () => {
  return (
    <footer className="bg-[#F5F5F5] p-4 text-xs text-black text-opacity-70 md:p-6 md:text-sm">
      {footerContent.map((disclaimer, index) => {
        return (
          <p key={index} className="last:p-0">
            {disclaimer}
          </p>
        )
      })}
    </footer>
  )
}
