import { Auth0Provider, Auth0ProviderOptions, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { parseAuth0Id } from '@home-in/utilities/dist/string'
import { ThemeProvider } from '@mui/material'
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { memo, PropsWithChildren, useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider as ReduxProvider } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'
import { SvgDefs } from '@elements/icons/illustration'
import { FallbackError } from '@elements/status-handlers/fallback-error'
import { FullPageLoader } from '@elements/status-handlers/full-page-loader'
import { useMobileAppAuth } from '@hooks/deviceHook'
import { useAppReadyToLoad } from '@hooks/useAppReadyToLoad'
import { useHandleUncaughtErrors } from '@hooks/useHandleUncaughtErrors'
import { useRouteChange } from '@hooks/useRouteChange'
import { useSetupAccessTokensForReactNative } from '@hooks/useSetupAccessTokensForReactNative'
import { useSetupAnalytics } from '@hooks/useSetupAnalytics'
import { AuthenticatedWrapper } from '@layouts/authenticated-wrapper'
import { MaintenancePage } from '@layouts/maintenance-page'
import { PublicWrapper } from '@layouts/public-wrapper'
import { useAppDispatch } from '@redux/hooks'
import { setUserIdentifier } from '@redux/reducers/analytics'
import { LDFeatureFlag, setFlags } from '@redux/reducers/app'
import { setUser } from '@redux/reducers/auth'
import { setIntercomUnread } from '@redux/reducers/notifications'
import { store } from '@redux/store'
import { Themes } from '@theme/constants'
import { getClientId, getRedirectUri } from '@utils/helpers/auth0.helpers'
import { jwt } from '@utils/helpers/jwt.helpers'
import { launchdarkly } from '@utils/helpers/launchdarkly.helpers'
import { getSessionStorageItem, setSessionStorageItem } from '@utils/storage'
import { AppDataBridge } from 'components/helpers/app-data-bridge'
import '../../styles/globals.css'
import '../../styles/theme-overrides.css'
import { ErrorBoundary } from '../error-boundary'
import type { AppProps } from 'next/app'

/**
 * Initialise the Providers required by the app -
 *  - Auth0
 *  - Redux
 *  - Intercom
 *  - Material UI
 */

export const Providers = ({ children }: PropsWithChildren<{}>) => {
  const flags = useFlags<LDFeatureFlag>()
  const router = useRouter()
  const mobileAppAuthRequired = useMobileAppAuth()

  useEffect(() => {
    launchdarkly.setFlags(flags)
  }, [flags])

  return (
    <Auth0Wrapper disabled={Boolean(mobileAppAuthRequired)}>
      <ErrorBoundary key={router.asPath}>
        <ReduxProvider store={store}>
          <IntercomProvider
            appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID!}
            onUnreadCountChange={(unreadCount) => store.dispatch(setIntercomUnread(unreadCount))}
          >
            <ThemeProvider theme={Themes.DefaultTheme}>
              <AppDataBridge />
              {children}
            </ThemeProvider>
          </IntercomProvider>
        </ReduxProvider>
      </ErrorBoundary>
    </Auth0Wrapper>
  )
}
const Auth0Wrapper = ({ disabled, children }: { disabled: boolean; children: React.ReactElement }) => {
  const router = useRouter()
  const isLenderApp = router.pathname.startsWith('/lender/')

  const Auth0ProviderOptions: Auth0ProviderOptions = {
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN!,
    clientId: getClientId(isLenderApp),
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE!,
    useRefreshTokens: true,
    maxAge: 86400,
    cacheLocation: 'localstorage',
    onRedirectCallback: (appState) => router.replace(appState?.returnTo || '/'),
    redirectUri: getRedirectUri(isLenderApp),
  }

  if (disabled) {
    return <>{children}</>
  }

  return <Auth0Provider {...Auth0ProviderOptions}>{children}</Auth0Provider>
}

/**
 * Some global functions that help us to:
 * - handle errors and send to Otter for logging
 * - change page title on route change
 * - set up our analytics providers
 */
const Global = memo(
  ({ pageTitle, bypassDefaultPageView }: { pageTitle: string; bypassDefaultPageView: boolean | undefined }) => {
    useHandleUncaughtErrors()
    useRouteChange({ pageTitle })
    useSetupAnalytics({ pageTitle, bypassDefaultPageView })
    useSetupAccessTokensForReactNative()
    return null
  }
)

/**
 * Some global functions (that require auth) that help us to:
 * - set the JWT in a global variable so we can retrieve outside of the React
 *   render context
 * - set the user information in Redux
 * - initalise the User information in LaunchDarkly so we get the correct flags for
 *   the user
 */
const GlobalAuthed = ({ children }: { children: React.ReactElement }) => {
  const router = useRouter()
  const { user, isLoading, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const flags = useFlags()
  const dispatch = useAppDispatch()
  const auth0LoginError: { reason: string; auth0Id?: string } =
    router.query['error'] === 'access_denied' && JSON.parse(decodeURI(router.query['error_description'] as string))

  enum AUTH0_CUSTOM_ERROR {
    // when a user's email is unverified
    UNVERIFIED_EMAIL_USER = 'unverifiedEmailUser',
    // when a user tries login with social account that has the same email as an existing Auth0 account
    DUPLICATE_SOCIAL_LOGIN = 'duplicateSocialLogin',
  }
  const hasLoginError = !!auth0LoginError?.reason
  /**
   * When there's a custom login error, auth0 redirects user to panda with query params: "error" and "error_description"
   * To restrict access to the app, we redirect the user back to auth0 login page with "ext-" headers to display the appropriate error message
   */
  useEffect(() => {
    switch (auth0LoginError?.reason) {
      case AUTH0_CUSTOM_ERROR.UNVERIFIED_EMAIL_USER:
        loginWithRedirect({
          'ext-errorType': AUTH0_CUSTOM_ERROR.UNVERIFIED_EMAIL_USER,
          'ext-userId': parseAuth0Id(auth0LoginError?.auth0Id as unknown as string)?.userId || '',
        }).then()
        break
      case AUTH0_CUSTOM_ERROR.DUPLICATE_SOCIAL_LOGIN:
        loginWithRedirect({
          'ext-errorType': AUTH0_CUSTOM_ERROR.DUPLICATE_SOCIAL_LOGIN,
        }).then()
        break
      default:
    }
  }, [])

  // Make hooks available outside of the React context, i.e. in an RTK Query API
  jwt.setAccessTokenSilently(getAccessTokenSilently)
  jwt.setIdTokenClaims(getIdTokenClaims)

  useEffect(() => {
    if (!user) return

    dispatch(setUser({ user }))
    // Match the users' Auth0 ID with their Mixpanel anonymous ID
    dispatch(setUserIdentifier(user.sub))

    return () => {
      dispatch(setUser({ user: null }))
    }
  }, [user])

  // Dispatch LD flags to store
  useEffect(() => {
    dispatch(setFlags(flags))
  }, [flags])

  if (isLoading) {
    return <FullPageLoader />
  }

  return <>{hasLoginError ? null : children}</>
}

const ComponentsLoadOnceOnAppRender = () => {
  // const flags = useFlags()
  /* IMPORTANT: This content will show up as a flash of content before showing the loading page. So make sure you don't show any components you add here on loading or on error */
  return (
    <ReduxProvider store={store}>
      {/* Only load the toaster once to avoid flickering of the toast notification whenever you change screens */}
      <Toaster
        toastOptions={{
          className: 'sm:mt-14 sm:-mb-14',
          id: 'unique-toaster',
        }}
      />
      {/* Only show once so the NPS modal does not retrigger when you change screens */}
      {/* {flags?.npsModal && <SettlementSuccessNpsModal propertyId=/>} */}
    </ReduxProvider>
  )
}

/**
 * The main app component that wraps all pages.
 *
 * Waits until LaunchDarkly has loaded to display anything. This allows us to set global-level
 * access flags that can disable the application when we need to enter maintenance.
 */
const HomeInApp = ({
  Component,
  pageProps,
}: AppProps<{
  title: string
  public: boolean
  bypassDefaultPageView?: boolean
  showBack?: boolean
}>) => {
  const router = useRouter()
  const ready = useAppReadyToLoad()

  // Setup bypass launch darkly conditions
  const guardKey = 'letHomieIn' // Hard to guess... maybe?
  const guardValue = 'home-in'
  const bypassLD = router.query[guardKey] === guardValue
  const skipKillswitchForSession = getSessionStorageItem(guardKey) === guardValue
  if (bypassLD && !skipKillswitchForSession) setSessionStorageItem(guardKey, guardValue)
  const killswitch = useFlags()?.killswitch || false
  const successfullyLoadApp = !killswitch || bypassLD || skipKillswitchForSession

  // How to authenticate the user
  const mobileAppAuthRequired = useMobileAppAuth()

  const AppLoading = () => (
    <>
      <FullPageLoader />
      <FallbackError />
    </>
  )

  if (typeof mobileAppAuthRequired === 'undefined') {
    return <AppLoading />
  }

  const isWebsitePublic = pageProps.public

  const WebsiteWithNoAuthentication = () => (
    <PublicWrapper {...pageProps}>
      <Component {...pageProps} />
    </PublicWrapper>
  )

  const MobileApp = () => (
    <AuthenticatedWrapper {...pageProps}>
      <Component {...pageProps} />
    </AuthenticatedWrapper>
  )

  const WebAuthWrapper = withAuthenticationRequired(AuthenticatedWrapper, { onRedirecting: () => <FullPageLoader /> })

  const WebApp = () => (
    <GlobalAuthed>
      <WebAuthWrapper {...pageProps}>
        <Component {...pageProps} />
      </WebAuthWrapper>
    </GlobalAuthed>
  )

  const MainAppComponent = () => {
    if (isWebsitePublic) return <WebsiteWithNoAuthentication />
    if (successfullyLoadApp) {
      return !mobileAppAuthRequired ? <WebApp /> : <MobileApp />
    }
    return <MaintenancePage />
  }

  return (
    <>
      {/* DO NOT MOVE THIS COMPONENT. This needs to run on every page and can't be hidden behind a launch darkly flag since it no longer renders once on app render and it re-renders creating a bad flickering experience for our toast notifications, and other side effects that will grow when this component is used */}
      <ComponentsLoadOnceOnAppRender />
      {ready ? (
        <Providers>
          <Global pageTitle={pageProps.title} bypassDefaultPageView={pageProps.bypassDefaultPageView} />
          <Head>
            <title>{pageProps?.title ? `${pageProps.title} | ` : ''}Home-in</title>
            <link rel="shortcut icon" href="/favicon.ico" />
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
          </Head>
          <MainAppComponent />
          {/* we need to load all the svg defs separately, otherwise we get conflicting IDs */}
          <SvgDefs />
        </Providers>
      ) : (
        <AppLoading />
      )}
    </>
  )
}

export default withLDProvider<any>({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID!,
  options: {
    privateAttributes: ['email'],
    streaming: true,
  },
})(HomeInApp)
