import {
  AddSellPropertyInfoRequestType,
  GetSellPropertyInfoResponse,
  ManukaSellPropertyInfoType,
} from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/query/react'
import { trackEventsInApiCall } from '@redux/utils/analytics'
import { AnalyticsCategories, AnalyticsEventAPICallEventNames } from '@utils/helpers/analytics.enum'
import { baseQueryWithHeaders } from './custom-base-query'

const checklistCacheTags = ['SellPropertyInfo'] as const

export const sellPropertyInfoApi = createApi({
  reducerPath: 'sellPropertyInfoApi',
  baseQuery: baseQueryWithHeaders('bee/v1/'),
  tagTypes: checklistCacheTags,
  endpoints: (builder) => ({
    addSellPropertyInfo: builder.mutation<AddSellPropertyInfoRequestType, any>({
      query: (body) => ({ url: 'sell-property-info', method: 'POST', body }),
      invalidatesTags: ['SellPropertyInfo'],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.AddSellPropertyInfo,
            category: AnalyticsCategories.Property,
          },
        })
        await queryFulfilled
      },
    }),
    getSellPropertyInfo: builder.query<ManukaSellPropertyInfoType[], void>({
      query: () => ({ url: 'sell-property-info', method: 'GET' }),
      keepUnusedDataFor: 5,
      providesTags: ['SellPropertyInfo'],
      transformResponse: (response: GetSellPropertyInfoResponse) => {
        if (!response?.data || response?.data?.length < 1) {
          return []
        }
        return response.data
      },
    }),
  }),
})

export const { useAddSellPropertyInfoMutation, useGetSellPropertyInfoQuery } = sellPropertyInfoApi
