import { Assistant } from '@home-in/models'
import React from 'react'
import { displayPhoneNumber } from '../utils/customers'

export const LenderAssistantCard = ({ assistant }: { assistant: Assistant }) => {
  const { name, email, phone } = assistant
  return (
    <a href={`mailto:${assistant.email}`}>
      <div className="card-elevation-6 w-60 rounded border-[#E5E5E5] p-3 md:p-4">
        <div className="flex flex-col gap-2.5">
          <span className="opacity-60">Hi, I am {name}, your buying assistant</span>
          {email && <span className="underline">Email: {email}</span>}
          {phone && <span>Phone: {displayPhoneNumber(phone)}</span>}
        </div>
      </div>
    </a>
  )
}
