import { checklistApi } from '@redux/apis/checklist'
import { homeApi } from '@redux/apis/home'
import { profileApi } from '@redux/apis/profile'
import { sellPropertyApi } from '@redux/apis/sell-property'
import { listenerMiddleware } from '@redux/listeners'
import { setLoaded, setUser } from '@redux/reducers/auth'
import { isLender } from '@utils/helpers/user.helpers'

export const userListeners = () => {
  /**
   * Once the user is authenticated, call these APIs immediately.
   */
  listenerMiddleware.startListening({
    actionCreator: setUser,
    effect: async (action, api) => {
      if (!action.payload || !action.payload.user) {
        return
      }
      if (isLender(action.payload?.user)) {
        api.dispatch(setLoaded(true))
      } else {
        const getProfileAndSetLoading = async () => {
          await api.dispatch(profileApi.endpoints.getProfile.initiate())
          return api.dispatch(setLoaded(true))
        }

        await Promise.all([
          getProfileAndSetLoading(),
          api.dispatch(checklistApi.endpoints.getChecklist.initiate()),
          api.dispatch(sellPropertyApi.endpoints.getSellProperties.initiate()),
          api.dispatch(homeApi.endpoints.getHomeBuyingJourney.initiate()),
          api.dispatch(profileApi.endpoints.getServiceRequests.initiate()),
          api.dispatch(profileApi.endpoints.getUserMetadata.initiate()),
          api.dispatch(homeApi.endpoints.getTeam.initiate()),
        ])
      }
    },
  })
}
