import {
  AddLenderDetailsRequestType,
  GetHomeBuyingJourneyResponse,
  GetHomeBuyingJourneyWorkPartyResponse,
  LoanApplication,
} from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithHeaders } from './custom-base-query'

export type GetLenderQueryArg = {
  hbjId: string
}

export const homeApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: baseQueryWithHeaders('kangaroo/v1/'),
  tagTypes: ['Home', 'Team'],
  endpoints: (builder) => ({
    getHomeBuyingJourney: builder.query<GetHomeBuyingJourneyResponse, void>({
      query: () => {
        return 'home'
      },
      providesTags: ['Home'],
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: GetHomeBuyingJourneyResponse }) => response?.data,
    }),
    getTeam: builder.query<GetHomeBuyingJourneyWorkPartyResponse, void>({
      query: () => {
        return 'team'
      },
      providesTags: ['Team'],
      keepUnusedDataFor: 7200, // 2 hours
      transformResponse: (response: { data: GetHomeBuyingJourneyWorkPartyResponse }) => response?.data,
    }),
    getLender: builder.query<LoanApplication, GetLenderQueryArg>({
      query: ({ hbjId }) => {
        return `lenderTileDetails/hbj/${hbjId}`
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: LoanApplication }) => response?.data,
    }),
    addLender: builder.mutation<{ hbjId: string }, AddLenderDetailsRequestType>({
      query: (body) => {
        return { url: 'lender', method: 'POST', body }
      },
      invalidatesTags: ['Home', 'Team'],
      transformResponse: (response: { data: { hbjId: string } }) => response?.data,
    }),
  }),
})

export const { useGetHomeBuyingJourneyQuery, useGetTeamQuery, useGetLenderQuery, useAddLenderMutation } = homeApi
