import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FireToastNotificationsProps {
  result: Promise<any>
  successMessage?: string
  errorMessage?: string
  optimisticUIUpdate?: boolean
  onlyShowError?: boolean
}

export const notifications = createSlice({
  name: 'notifications',
  initialState: {
    intercomUnread: 0,
  },
  reducers: {
    fireToastNotifications: (_, __: PayloadAction<FireToastNotificationsProps>) => {},
    setIntercomUnread: (state, action: PayloadAction<number>) => {
      state.intercomUnread = action.payload
    },
  },
})

export const { fireToastNotifications, setIntercomUnread } = notifications.actions
export default notifications.reducer
