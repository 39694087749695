import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { documentsApi } from '@redux/apis/documents'
import { documentsApiV2, unscannedFileMetadata } from '@redux/apis/documents-v2'
import { domainApi } from '@redux/apis/domain'
import { localApi } from '@redux/apis/local'
import { pricingApi } from '@redux/apis/pricing'
import { profileApi } from '@redux/apis/profile'
import { listenerMiddleware } from '@redux/listeners'
import { rtkQueryErrorLogger } from '@redux/middleware/error-logger'
import { rehydrateStore } from '@redux/utils/rehydrate-store'
import { setupSubscriber } from '@redux/utils/store-subscriber'
import { acquisitionFunnelApi } from './apis/acquisition-funnel'
import { checklistApi } from './apis/checklist'
import { homeApi } from './apis/home'
import { lenderProfileApi } from './apis/lender-profile'
import { lenderSignUpApi } from './apis/lender-signup'
import { lenderApi, lenderCustomerJourneyApi, lenderCustomersApi } from './apis/lenders'
import { sellBankDetailsApi } from './apis/sell-bank-details'
import { sellChecklistApi } from './apis/sell-checklist'
import { sellPropertyApi } from './apis/sell-property'
import { sellPropertyInfoApi } from './apis/sell-property-info'
import acquisitionFunnelReducer from './reducers/acquisition-funnel'
import addPropertyReducer from './reducers/add-property'
import analyticsReducer from './reducers/analytics'
import appReducer from './reducers/app'
import authReducer from './reducers/auth'
import checklistReducer from './reducers/checklist'
import feedbackReducer from './reducers/feedback'
import homeReducer from './reducers/home'
import lenderReducer from './reducers/lender'
import lenderCustomerJourneyReducer from './reducers/lender-customerjourney'
import lenderCustomersReducer from './reducers/lender-customers'
import lenderProfileReducer from './reducers/lender-profile'
import modalReducer from './reducers/modal'
import notificationsReducer from './reducers/notifications'
import pageReducer from './reducers/page'
import profileReducer from './reducers/profile'
import sellReducer from './reducers/sell'

export const storeConfig = {
  reducer: {
    analytics: analyticsReducer,
    notifications: notificationsReducer,
    app: appReducer,
    auth: authReducer,
    feedback: feedbackReducer,
    checklist: checklistReducer,
    sell: sellReducer,
    home: homeReducer,
    lender: lenderReducer,
    modal: modalReducer,
    page: pageReducer,
    profile: profileReducer,
    lenderProfile: lenderProfileReducer,
    lenderCustomers: lenderCustomersReducer,
    lenderCustomerJourney: lenderCustomerJourneyReducer,
    acquisitionFunnel: acquisitionFunnelReducer,
    addProperty: addPropertyReducer,
    [checklistApi.reducerPath]: checklistApi.reducer,
    [sellPropertyApi.reducerPath]: sellPropertyApi.reducer,
    [sellChecklistApi.reducerPath]: sellChecklistApi.reducer,
    [sellBankDetailsApi.reducerPath]: sellBankDetailsApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [documentsApiV2.reducerPath]: documentsApiV2.reducer,
    [unscannedFileMetadata.reducerPath]: unscannedFileMetadata.reducer,
    [domainApi.reducerPath]: domainApi.reducer,
    [lenderApi.reducerPath]: lenderApi.reducer,
    [lenderCustomerJourneyApi.reducerPath]: lenderCustomerJourneyApi.reducer,
    [lenderCustomersApi.reducerPath]: lenderCustomersApi.reducer,
    [localApi.reducerPath]: localApi.reducer,
    [pricingApi.reducerPath]: pricingApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [lenderProfileApi.reducerPath]: lenderProfileApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [lenderSignUpApi.reducerPath]: lenderSignUpApi.reducer,
    [sellPropertyInfoApi.reducerPath]: sellPropertyInfoApi.reducer,
    [acquisitionFunnelApi.reducerPath]: acquisitionFunnelApi.reducer,
  },
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['modal/setModalContent', 'notifications/fireToastNotifications'],
        ignoredPaths: ['modal.content'],
      },
    })
      .prepend(listenerMiddleware.middleware)
      .concat(
        rtkQueryErrorLogger,
        checklistApi.middleware,
        documentsApi.middleware,
        documentsApiV2.middleware,
        unscannedFileMetadata.middleware,
        domainApi.middleware,
        lenderApi.middleware,
        lenderCustomersApi.middleware,
        lenderCustomerJourneyApi.middleware,
        localApi.middleware,
        pricingApi.middleware,
        profileApi.middleware,
        lenderProfileApi.middleware,
        homeApi.middleware,
        lenderSignUpApi.middleware,
        sellPropertyApi.middleware,
        sellChecklistApi.middleware,
        sellBankDetailsApi.middleware,
        sellPropertyInfoApi.middleware,
        acquisitionFunnelApi.middleware
      ),
  preloadedState: rehydrateStore(),
}

export const store = configureStore(storeConfig)

// Set up extra RTK Query Listeners
setupListeners(store.dispatch)

// Setup store subscriber to sync store to Local Storage
setupSubscriber(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
