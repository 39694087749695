import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { setAccessTokenHeader } from '@redux/utils/utils'

const baseQuery = (url = '') =>
  fetchBaseQuery({
    baseUrl: url.startsWith('http') ? url : `${process.env.NEXT_PUBLIC_BASE_API}${url}`,
    prepareHeaders: setAccessTokenHeader,
  })

export const baseQueryWithHeaders =
  (url = ''): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) =>
    baseQuery(url)(args, api, extraOptions)
