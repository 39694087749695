import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddLenderForm } from '@parts/add-lender-details-form'

type LenderDetails = Partial<Omit<AddLenderForm, 'lender'> & { name: string }>

export const lender = createSlice({
  name: 'lender',
  initialState: <LenderDetails>{
    name: undefined,
    email: undefined,
    applicationNumber: undefined,
    financialInstitution: undefined,
    otherInstitution: undefined,
  },
  reducers: {
    updateLender: (state, action: PayloadAction<LenderDetails>) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { updateLender } = lender.actions
export default lender.reducer
