import {
  Address,
  BuyingType,
  CHLStatus,
  CHLStatusType,
  DomainAddress,
  GetChlResponse,
  LenderReferralRequest,
  ManukaSellingPartnerType,
  OwnershipType,
  PurchaseDetails,
  PurchasingPartner,
  State,
  TransactionType,
  YesNo,
} from '@home-in/models'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query'
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { UseFormReturn } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { SellPropertyInfoFormSellingPartner } from '@features/sell-side/checklist/task/individual-tasks/request-contract-prep/v2/sell-property-info-form'
import { LenderReferralForm } from '@pages/lender-signup'
import {
  PurchaseDetailsForm,
  PurchaseDetailsFormWithPurchasingPartnerArray,
  PurchasingPartnerForm,
} from '@pages/profile/purchase-details/[hbjId]/[propertyId]'
import { formatFormDate, formatPegaDate } from '@utils/helpers/date.helpers'
import { getEnumKeyByEnumValue } from '@utils/helpers/enum.helpers'
import { jwt } from '@utils/helpers/jwt.helpers'

export const transformDomainAddress = (address: DomainAddress): Address => {
  return {
    id: address?.id || undefined,
    full_address: address?.address || '',
    street_number:
      `${address?.addressComponents?.unitNumber ? `${address?.addressComponents?.unitNumber}/` : ''}${
        address?.addressComponents?.streetNumber || ''
      }`.trim() || '',
    street_name:
      `${address?.addressComponents?.streetName || ''} ${address?.addressComponents?.streetTypeLong || ''}`.trim() ||
      '',
    postcode: address?.addressComponents?.postCode || '' || '',
    state: address?.addressComponents?.state || ('' as State),
    suburb: address?.addressComponents?.suburb || '',
    manual_entry: false,
  }
}

const constructFullAddress = (address: Address): string => {
  return `${address.street_number || ''} ${address.street_name || ''}, ${address.suburb || ''} ${address.state || ''} ${
    address.postcode || ''
  }`.trim()
}

/**
 * Transforms purchasing partner objects to an array.
 * The array is filtered to only include the 'active'
 * purchasing partner UUID's that the user wanted to submit.
 *
 * A purchasing partner not found in the active array was deleted
 * by the user inside the UI
 * @param data
 * @param activePurchasingPartnerUUIDs
 * @returns
 */
export const transformPurchasingPartnersObjToFilteredArray = ({
  data,
  activePurchasingPartnerUUIDs,
}: {
  data: PurchaseDetailsForm
  activePurchasingPartnerUUIDs: string[]
}): PurchasingPartnerForm[] => {
  const purchasingPartners = data.purchasing_partners || {}

  const keys: string[] = Object.keys(purchasingPartners)

  return activePurchasingPartnerUUIDs
    .filter((uuid) => keys.some((id) => id === uuid))
    .map((uuid) => purchasingPartners[uuid])
}
/**
 * Transforms selling partner objects to an array.
 * The array is filtered to only include the 'active'
 * selling partner UUID's that the user wanted to submit.
 *
 * A selling partner not found in the active array was deleted
 * by the user inside the UI
 * @param data
 * @param activePurchasingPartnerUUIDs
 * @returns
 */
export const transformSellingPartnersObjToFilteredArray = ({
  data,
  activeSellingPartnerUUIDs,
  hasSellingPartners,
}: {
  data: Record<string, SellPropertyInfoFormSellingPartner>
  activeSellingPartnerUUIDs: string[]
  hasSellingPartners: boolean
}): ManukaSellingPartnerType[] => {
  if (!hasSellingPartners || !data) return []
  const keys: string[] = Object.keys(data)

  return activeSellingPartnerUUIDs
    .filter((uuid) => keys.some((id) => id === uuid))
    .map((uuid) => {
      const sellingPartner = data[uuid]
      return {
        ...sellingPartner,
        ausNzResident: sellingPartner.ausNzResident === YesNo.Yes,
      }
    })
}

/**
 * Parses the Form Data to structure inline with API contract
 * @param data Purchase Details Form data from UI
 * @returns Parsed Purchase Details Object
 */
export const transformPurchaseDetailsRequestPayload = ({
  hbj_id,
  formData,
}: {
  hbj_id: string
  formData: PurchaseDetailsFormWithPurchasingPartnerArray
}): PurchaseDetails => ({
  hbj_id,
  transaction_name: formData.property_name,
  transaction_type: formData.purchase_type,
  first_property_purchase: formData.first_purchase === YesNo.Yes,
  buying_type: getEnumKeyByValue(BuyingType, formData.buying_type) as keyof typeof BuyingType,
  ownership_type: getEnumKeyByValue(OwnershipType, formData.ownership_type) as keyof typeof OwnershipType,
  tenant_ownership: formData.tenant_ownership,
  purchasing_partners:
    formData.purchasing_partners.length > 0 ? transformPurchasingPartner(formData.purchasing_partners) : [],
})

export const transformPurchaseDetailsToForm = (data: PurchaseDetails): PurchaseDetailsForm => {
  let partners: Record<string, PurchasingPartnerForm> | null = null
  data.purchasing_partners.forEach((partner) => {
    if (!partners) partners = {}
    partners[uuidv4()] = transformPurchasingPartnerToForm(partner)
  })

  return {
    property_name: data.transaction_name,
    purchase_type: TransactionType[data.transaction_type],
    first_purchase: data.first_property_purchase ? YesNo.Yes : YesNo.No,
    tenant_ownership: data.tenant_ownership,
    ownership_type: OwnershipType[data.ownership_type],
    buying_type: BuyingType[data.buying_type],
    purchasing_partners: partners ?? {},
  }
}

export const transformPurchasingPartnerToForm = (partner: PurchasingPartner): PurchasingPartnerForm => {
  const result: PurchasingPartnerForm = {
    first_name: partner.first_name,
    middle_name: partner.middle_name,
    last_name: partner.last_name,
    date_of_birth: formatFormDate(partner.dob),
    email: partner.email,
    tenant_ownership: partner.tenant_ownership,
    mobile: partner.phone,
    different_mailing_address: partner.use_mailing_address,
    residency: partner.residency,
    first_property_purchase: partner.first_property_purchase ? YesNo.Yes : YesNo.No,
    buying_type: BuyingType[partner.buying_type],
    foreign_investment_approval: partner.foreign_investment_approval ? YesNo.Yes : YesNo.No,
  }

  if (partner.residential_address.manual_entry) {
    result.address_is_manual = '1'
    result.manual_address = partner.residential_address
  } else {
    result.full_address = partner.residential_address.full_address
  }

  if (partner.mailing_address?.manual_entry) {
    result.mailing_address_is_manual = '1'
    result.manual_mailing_address = partner.mailing_address
  } else {
    result.full_mailing_address = partner.mailing_address?.full_address
  }

  return result
}

export const mergeAddress = ({
  isManual,
  manualAddress,
  domainAddress,
}: {
  isManual: boolean
  manualAddress: Address | undefined
  domainAddress: DomainAddress | undefined
}): Address | undefined => {
  if (!manualAddress && !domainAddress) return undefined

  return isManual
    ? {
        ...manualAddress!,
        state: manualAddress!.state as State,
        full_address: constructFullAddress(manualAddress!),
      }
    : transformDomainAddress(domainAddress!)
}

export const transformPurchasingPartner = (data: PurchasingPartnerForm[]): PurchasingPartner[] =>
  data.map((data) => {
    return {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      dob: formatPegaDate(data.date_of_birth),
      email: data.email,
      tenant_ownership: data.tenant_ownership,
      phone: data.mobile,
      residential_address: mergeAddress({
        isManual: data.address_is_manual === '1',
        manualAddress: data.manual_address,
        domainAddress: data.address,
      }) as Address,
      mailing_address: mergeAddress({
        isManual: data.mailing_address_is_manual === '1',
        manualAddress: data.manual_mailing_address,
        domainAddress: data.mailing_address,
      }) as Address,
      use_mailing_address: data.different_mailing_address || false,
      aus_nz_resident: getAusNzResidentBoolean(data.residency) || false,
      residency: data.residency,
      first_property_purchase: data.first_property_purchase === YesNo.Yes,
      buying_type: getEnumKeyByValue(BuyingType, data.buying_type) as keyof typeof BuyingType,
      foreign_investment_approval: data.foreign_investment_approval === YesNo.Yes,
    }
  })

export const transformLenderReferralRequestPayload = (data: LenderReferralForm): LenderReferralRequest => {
  return {
    lender_email: data.lender_email,
    customer: {
      full_name: data.full_name,
      email: data.email,
      mobile: data.mobile,
    },
    loan: {
      chl_status: getEnumKeyByEnumValue(CHLStatus, data.chl_status) as CHLStatusType,
      chl_number: data.chl_number,
      purchase_state: data.purchase_state as State,
      loan_amount: data.loan_amount ? parseInt(data.loan_amount!, 10) : undefined,
    },
  }
}

export type ChlTriggerType = LazyQueryTrigger<
  QueryDefinition<
    string,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    never,
    GetChlResponse,
    'checklistApi'
  >
>

export const CHL_REQUIRED_LENGTH = 11

export const validateChlNumber = async (
  methods: UseFormReturn<any, any>,
  trigger: ChlTriggerType,
  chlFieldName: string
): Promise<boolean | null> => {
  const chlNumber = methods.getValues(chlFieldName)
  if (!chlNumber || (chlNumber && chlNumber.length !== CHL_REQUIRED_LENGTH)) return null
  const result = await trigger(chlNumber)
  if (result.data?.is_duplicate) {
    methods.setError(chlFieldName, { message: 'This CHL number is already in use' })
    return false
  }
  methods.clearErrors(chlFieldName)
  return true
}

/**
 * The function for fetchBaseQueryArgs.prepareHeaders which sets the auth token for Raven API requests
 * @link https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#setting-default-headers-on-requests
 */
export const setAccessTokenHeader = async (headers: Headers) => {
  const accessToken = await jwt.getAccessTokenSilently?.()?.()

  if (accessToken) {
    headers.set('authorization', `Bearer ${accessToken}`)
  }

  return headers
}

const getEnumKeyByValue = (enumObj: any, value: string) => {
  const indexOfS = Object.values(enumObj).indexOf(value)
  return Object.keys(enumObj)[indexOfS]
}

const getAusNzResidentBoolean = (residency: string | unknown): boolean | undefined => {
  if (typeof residency !== 'string' || residency === '') {
    return undefined
  }
  const lowercaseResidency = residency.toLowerCase()

  return lowercaseResidency === 'australia' || lowercaseResidency === 'new zealand'
}
