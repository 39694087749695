import { useAppSelector } from '@redux/hooks'
import useActiveHbj from './useActiveHbj'

const useActiveWorkParty = () => {
  const { activeHbjId } = useActiveHbj()
  const { team, loaded } = useAppSelector((state) => state.home.workParty)
  const activeWorkParty = team?.find((party) => party.hbj_id === activeHbjId)

  return { activeWorkParty, loaded }
}

export default useActiveWorkParty
