import { FEEDBACK_LOCAL_STATE_KEY } from '@redux/reducers/feedback'
import { parseStorageItem } from '@utils/storage'

export const rehydrateStore = () => {
  if (typeof window === 'undefined') return {}

  const key = 'applicationState'

  const applicationState = parseStorageItem(key)
  const timestamp = parseStorageItem(`${key}_timestamp`)

  if (!applicationState) return {}

  const currentTime = new Date().getTime()

  if (timestamp?.timestamp && timestamp?.expiry && currentTime - timestamp?.timestamp >= timestamp?.expiry) {
    return {}
  }
  const auth0id = applicationState?.auth?.user?.sub
  const feedbackState = parseStorageItem(`${FEEDBACK_LOCAL_STATE_KEY}_${auth0id}`)
  const feedback = feedbackState ? feedbackState : { emojiFeedback: null, auth0id }

  // Add any store values we want to rehydrate here
  const { app, checklist, lender, sell } = applicationState

  return {
    app: {
      ...app,
      continueOnboardingAfterAcceptingTerms: false,
      utmId: undefined,
    },
    checklist: {
      ...checklist,
      currentProperty: undefined,
      currentChecklist: undefined,
      taskUploads: [],
    },
    lender,
    feedback,
    sell,
  }
}
