import { ProfileItem } from '@home-in/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const profile = createSlice({
  name: 'profile',
  initialState: <Partial<ProfileItem>>{},
  reducers: {
    setProfile: (state, action: PayloadAction<Partial<ProfileItem> | null>) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setProfile } = profile.actions
export default profile.reducer
