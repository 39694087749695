import StackTrace from 'stacktrace-js'
import Package from '../../../package.json'
import type { AddLogSchemaType } from '@home-in/models'

type AddLogSchemaTypeWithError = AddLogSchemaType & { error?: Error }

/**
 * Enhance obfuscated Error stack with source maps
 */
const sourceMapErrorStack = async (addLog: AddLogSchemaTypeWithError) => {
  try {
    if (addLog.error) {
      const stackframes = await StackTrace.fromError(addLog.error)
      const sourceMappedStack = stackframes.map((sf) => sf.toString()).join('\n')
      addLog.stackTrace = sourceMappedStack + '\n\n*** Unmapped stack:\n' + addLog.stackTrace
    }
  } catch (err) {
    addLog.stackTrace = (<Error>err).message + (addLog.stackTrace ?? '')
  }
}

export const sendClientLog = async (addLog: Omit<AddLogSchemaTypeWithError, 'platform'>): Promise<void> => {
  if (!addLog.message || !addLog.severity) return

  try {
    if (process.env.NEXT_PUBLIC_ENABLE_SOURCE_MAPS === 'true') {
      await sourceMapErrorStack(addLog)
    }

    // Clean up log obj before sending it
    delete addLog.error

    fetch(`${process.env.NEXT_PUBLIC_BASE_API!}otter/v1/log`, {
      method: 'POST',
      body: JSON.stringify({
        ...addLog,
        platform: (typeof window !== 'undefined' && window._platform) || 'web',
        version: Package.version,
      }),
    })
  } catch (e) {
    // we don't want to trigger an Otter log if an Otter log fails to send - circular request
  }
}
