import { useEffect, useRef } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useMobileQuery } from '@hooks/layoutHooks'
import useActiveHbj from '@hooks/useActiveHbj'
import useActiveWorkParty from '@hooks/useActiveWorkParty'
import { useCurrentChecklist } from '@hooks/useCurrentChecklist'
import { useAppSelector } from '@redux/hooks'

/**
 * This hook sets up the Intercom chat widget with the user's profile information
 */
const useIntercomSetup = () => {
  const intercom = useIntercom()
  const profile = useAppSelector((state) => state.profile)
  const booted = useRef(false)
  const { activeWorkParty } = useActiveWorkParty()
  const isMobile = useMobileQuery()
  const waratahProperty = useCurrentChecklist()
  const state = waratahProperty?.state
  const address = waratahProperty?.address
  const hbjId = waratahProperty?.hbjId
  const settlementDate = waratahProperty?.settlementDateTime

  const { activeHbj } = useActiveHbj()

  useEffect(() => {
    if (!booted.current && profile && profile?.id && profile?.email) {
      intercom.boot({
        name: profile.first_name + ' ' + profile.last_name,
        phone: profile.mobile,
        email: profile.email,
        userId: profile.id,
        userHash: profile?.chatKey,
        hideDefaultLauncher: isMobile,
      })
      booted.current = true
    }
  }, [profile])

  useEffect(() => {
    if (booted.current) {
      intercom.update({ hideDefaultLauncher: isMobile })
    }
  }, [booted.current, isMobile])

  useEffect(() => {
    const buyingAssistantName = activeWorkParty?.your_personal_contact?.full_name
    const conveyancerName = activeWorkParty?.partner_law_firm?.full_name
    const conveyancingFirm = activeWorkParty?.partner_law_firm?.organisation

    // Update the user's Intercom profile with BA and Conveyancer information
    // Intercom uses title case keys for custom attributes
    if (
      booted.current &&
      (activeWorkParty?.your_personal_contact?.full_name ||
        activeWorkParty?.partner_law_firm?.full_name ||
        state ||
        settlementDate ||
        activeHbj?.transaction_phase ||
        address ||
        hbjId)
    ) {
      intercom.update({
        customAttributes: {
          ...(!!buyingAssistantName ? { 'Buying Assistant': buyingAssistantName } : {}),
          ...(!!conveyancerName ? { Conveyancer: conveyancerName } : {}),
          ...(!!state ? { State: state } : {}),
          ...(!!hbjId ? { HBJ: hbjId } : {}),
          ...(!!address ? { Address: address } : {}),
          ...(!!settlementDate
            ? {
                'Settlement Date': new Date(settlementDate).toLocaleDateString('en-AU', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }),
              }
            : {}),
          ...(!!activeHbj?.transaction_phase ? { 'Transaction Phase': activeHbj?.transaction_phase } : {}),
        },
      })
    }
  }, [activeWorkParty, booted.current, profile.id, settlementDate, activeHbj, state, address, hbjId])
}

export default useIntercomSetup
