import { useEffect, useState } from 'react'

export const useMobileQuery = () => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const queryChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches)
    }
    const mq = window.matchMedia('(max-width: 768px)')
    mq.addEventListener('change', queryChange)
    setIsMobile(mq.matches)
    return () => {
      mq.removeEventListener('change', queryChange)
    }
  }, [])

  return isMobile
}
