import { Dispatch } from '@reduxjs/toolkit'
import { AddPropertyFormFields } from '@elements/add-property/add-property-form'
import { ButtonStyle } from '@elements/button'
import { AcquisitionFunnelState } from '@redux/reducers/acquisition-funnel'

export enum FunnelPageSlug {
  GET_STARTED = 'get-started',
  HAVE_YOU_FOUND_THE_PROPERTY = 'have-you-found-the-property',
  STATE_OF_INTEREST = 'state-of-interest',
  HOW_SOON_LOOKING_TO_BUY = 'how-soon-looking-to-buy',
  PROPERTY_DETAILS = 'property-details',
  TYPE_OF_SALE = 'type-of-sale',
  HAVE_COS = 'have-cos',
  COS_SIGNED = 'cos-signed',
  AUCTION_DATE = 'auction-date',
  HOME_IN_CVP = 'home-in-cvp',
}

export interface FunnelPageButton {
  name: string
  callback?: (dispatch: Dispatch, state: AcquisitionFunnelState) => void
  variant: ButtonStyle
  analyticsValue: string
}

export interface FunnelPageData {
  title: string
  analyticsEventName: string
  slug: FunnelPageSlug
  component?: React.FC
  buttons?: FunnelPageButton[]
}

export type FunnelFlowData = FunnelPageSlug[]

export type PropertyDetailsFormFields = Pick<
  AddPropertyFormFields,
  'address' | 'manual_address' | 'address_is_manual' | 'propertyType'
>
