import { Assistant } from '@home-in/models'
import { useAppSelector } from '@redux/hooks'

export const useGetLenderAssistant = () => {
  const { buyingAssistant } = useAppSelector((state) => state.lenderProfile)
  if (!buyingAssistant) return

  return {
    name: `${buyingAssistant.firstName} ${buyingAssistant.lastName}`,
    email: buyingAssistant.email,
    phone: buyingAssistant.phone,
  } as Assistant
}
