export const getStorageItem = (key: string) => {
  if (typeof window === 'undefined') return null
  return window.localStorage.getItem(key)
}

export const setStorageItem = (key: string, value: string, withTimestamp = false, expiry = 8640000) => {
  if (typeof window === 'undefined') return
  window.localStorage.setItem(key, value)
  // set timestamp to epoch
  if (withTimestamp) {
    window.localStorage.setItem(
      `${key}_timestamp`,
      JSON.stringify({
        timestamp: new Date().getTime(),
        expiry,
      })
    )
  }
}

export const removeStorageItem = (key: string) => {
  if (typeof window === 'undefined') return null
  return window.localStorage.removeItem(key)
}

export const parseStorageItem = (key: string) => {
  if (typeof window === 'undefined') return null
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(value!)
  } catch {
    return null
  }
}

export const getSessionStorageItem = (key: string) => {
  if (typeof window === 'undefined') return null
  return window.sessionStorage.getItem(key)
}

export const setSessionStorageItem = (key: string, value: string) => {
  if (typeof window === 'undefined') return
  window.sessionStorage.setItem(key, value)
}
