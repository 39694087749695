import { Address, PropertySaleType, PropertyType, TransactionType } from '@home-in/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FunnelFlowData } from '@features/acquisition-funnel/data/types'

export interface PropertyDetails {
  address: Address
  propertyType?: PropertyType
  saleType?: PropertySaleType
  auctionDate?: string
  hasCos?: boolean
  cosSigned?: boolean
}

export enum BuySideFunnelVariant {
  HAS_PROPERTY,
  NO_PROPERTY,
}

export interface AcquisitionFunnelState {
  transactionType: TransactionType
  propertyDetails: PropertyDetails
  buySideFunnelVariant: BuySideFunnelVariant
  currentFlow: FunnelFlowData
}

export const acquisitionFunnel = createSlice({
  name: 'acquisitionFunnel',
  initialState: <AcquisitionFunnelState>{
    propertyDetails: <PropertyDetails>{},
  },
  reducers: {
    setTransactionType: (state, action: PayloadAction<TransactionType>) => {
      state.transactionType = action.payload
    },
    setPropertyAddress: (state, action: PayloadAction<Address>) => {
      state.propertyDetails.address = action.payload
    },
    setPropertyType: (state, action: PayloadAction<PropertyType>) => {
      state.propertyDetails.propertyType = action.payload
    },
    setPropertySaleType: (state, action: PayloadAction<PropertySaleType>) => {
      state.propertyDetails.saleType = action.payload
    },
    setPropertyAuctionDate: (state, action: PayloadAction<string>) => {
      state.propertyDetails.auctionDate = action.payload
    },
    setPropertyCosSigned: (state, action: PayloadAction<boolean>) => {
      state.propertyDetails.cosSigned = action.payload
    },
    setCurrentFlow: (state, action: PayloadAction<FunnelFlowData>) => {
      state.currentFlow = action.payload
    },
    setBuySideFunnelVariant: (state, action: PayloadAction<BuySideFunnelVariant>) => {
      state.buySideFunnelVariant = action.payload
    },
    setHasCos: (state, action: PayloadAction<boolean>) => {
      state.propertyDetails.hasCos = action.payload
    },
  },
})

export const {
  setTransactionType,
  setPropertyAddress,
  setPropertyType,
  setPropertySaleType,
  setPropertyAuctionDate,
  setPropertyCosSigned,
  setCurrentFlow,
  setBuySideFunnelVariant,
  setHasCos,
} = acquisitionFunnel.actions
export default acquisitionFunnel.reducer
