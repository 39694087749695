import { Address, DomainAddress, SellSideChecklistSchemaType, MuscovyPropertySchemaType } from '@home-in/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SellSideTaskWithMetadata } from '@features/checklist/data/checklist-builder'

export interface AddSellPropertyFields {
  address: DomainAddress
  manual_address: Address
  address_is_manual?: string
}

interface SellState {
  sellProperties: MuscovyPropertySchemaType[]
  removedSellProperties: string[]
  sellChecklists: SellChecklist[]
}

export interface SellChecklist extends SellSideChecklistSchemaType {
  tasks: SellSideTaskWithMetadata[]
}

export const sell = createSlice({
  name: 'sell',
  initialState: <SellState>{
    sellProperties: [],
    removedSellProperties: [],
    sellChecklists: [],
  },
  reducers: {
    addSellProperty: (state, action: PayloadAction<MuscovyPropertySchemaType>) => {
      state.sellProperties = [action.payload, ...state.sellProperties]
    },
    setSellProperties: (state, action: PayloadAction<MuscovyPropertySchemaType[]>) => {
      state.sellProperties = action.payload
    },
    addRemovedSellProperty: (state, action: PayloadAction<string>) => {
      state.removedSellProperties = [action.payload, ...state.removedSellProperties]
    },
    deleteRemovedSellProperty: (state, action: PayloadAction<string>) => {
      state.removedSellProperties = state.removedSellProperties.filter((property) => property !== action.payload)
    },
    setSellChecklists: (state, action: PayloadAction<SellChecklist[]>) => {
      state.sellChecklists = action.payload
    },
  },
})

export const {
  addSellProperty,
  setSellProperties,
  addRemovedSellProperty,
  deleteRemovedSellProperty,
  setSellChecklists,
} = sell.actions
export default sell.reducer
