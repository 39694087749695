import { startAppListening } from '@redux/listeners'
import { resetCategories, setCurrentChecklist, setChecklists } from '@redux/reducers/checklist'

export const checklistListeners = () => {
  startAppListening({
    actionCreator: setChecklists,
    effect: async (action, api) => {
      const state = api.getState()

      if (!state.checklist.currentChecklist) {
        api.dispatch(setCurrentChecklist(action.payload?.[0]))
      }

      if (state.checklist.currentChecklist) {
        // Update the current property so it's not a cached result from another user
        const isCurrentPropertyFromCurrentUser = action.payload.some(
          ({ propertyId }) => propertyId === state.checklist.currentChecklist?.propertyId
        )
        if (!isCurrentPropertyFromCurrentUser) {
          api.dispatch(setCurrentChecklist(action.payload[0]))
        }

        // Update the active current property if there are any changes
        const newActiveProperty = action.payload.find(
          ({ propertyId }) => propertyId === state.checklist.currentChecklist?.propertyId
        )
        if (newActiveProperty && newActiveProperty !== state.checklist.currentChecklist) {
          api.dispatch(setCurrentChecklist(newActiveProperty))
        }
      }
    },
  })
  startAppListening({
    actionCreator: setCurrentChecklist,
    effect: async (action, api) => {
      const originalState = api.getOriginalState()

      if (originalState.checklist.currentChecklist?.propertyId !== action.payload?.propertyId) {
        api.dispatch(resetCategories())
      }
    },
  })
}
