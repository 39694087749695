import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, PropsWithChildren } from 'react'
import { useIntercom } from 'react-use-intercom'
import Illustration, { Illustrations } from '@elements/icons/illustration'
import { LenderAssistantCard } from '@features/lenders/components/lender-assistant-card'
import { useGetLenderAssistant } from '@hooks/useGetLenderAssistant'
import { useAppSelector, useAppDispatch } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { classNames } from '@utils/helpers/classNameHelper'
import Logo from './logo'

const MobileNavigationContainer: FC<{}> = ({ children }) => (
  <nav className="fixed bottom-0 z-50 flex w-full select-none justify-evenly bg-white py-2 shadow-navShadow md:hidden">
    {children}
  </nav>
)

export const LenderNavigation = () => {
  const { hide, isOpen, show } = useIntercom()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const lenderAssistant = useGetLenderAssistant()

  const menuItems: MenuItem[] = [
    {
      key: 'lender-customers',
      name: 'Customers',
      illustration: Illustrations.lenderPeople,
      visible: true,
      isCurrent: router.route.startsWith('/lender/customers'),
      callback: () => {
        dispatch(
          trackButtonClick({
            action: 'Lender | Customers nav menu',
            category: AnalyticsCategories.Navigation,
          })
        )

        router.push('/lender/customers')
      },
    },
    {
      key: 'intercom',
      name: 'Get in touch',
      illustration: Illustrations.messages,
      visible: true,
      callback: () => {
        dispatch(
          trackButtonClick({
            action: `Lender | Intercom ${isOpen ? 'closed' : 'opened'}`,
            category: AnalyticsCategories.GetInTouch,
          })
        )
        if (isOpen) hide()
        else {
          show()
        }
      },
      isCurrent: isOpen,
    },
    {
      key: 'lender-profile',
      name: 'Profile',
      illustration: Illustrations.person,
      url: '/lender/profile',
      visible: true,
      isCurrent: router.route.startsWith('/lender/profile'),
    },
  ]

  return (
    <>
      <aside className={`fixed top-4 hidden h-full w-72 select-none bg-white md:block`}>
        <Link href={'/'} passHref>
          <a>
            <Logo innerClassName="w-[8.5rem]" />
          </a>
        </Link>
        <nav>
          {menuItems
            .filter((item) => item.visible)
            .map((item) => (
              <SidebarItem key={item.key} item={item} />
            ))}
        </nav>
        {lenderAssistant && (
          <div className="absolute bottom-10 left-5">
            <LenderAssistantCard assistant={lenderAssistant} />
          </div>
        )}
      </aside>
      <MobileNavigationContainer>
        {menuItems
          .filter((item) => item.visible)
          .map((item) => (
            <MobileNavItem key={item.key} item={item} />
          ))}
      </MobileNavigationContainer>
    </>
  )
}

const NewMessagesBubble = () => {
  const unreadIntercomMessages = useAppSelector((state) => state.notifications.intercomUnread)

  return !!unreadIntercomMessages ? (
    <div className="absolute left-[60%] top-[-1px] flex h-[10px] w-[10px] items-center justify-center rounded-full bg-error text-center md:left-[32px] md:top-[12px]"></div>
  ) : null
}

const MobileNavItem = ({ item }: { item: MenuItem }) => {
  const linkCSSOptions = {
    buySide: 'mobile-padding-bottom relative flex shrink-0 grow basis-1/4 cursor-pointer flex-col items-center',
    sellSide: 'mobile-padding-bottom relative flex cursor-pointer flex-col items-center justify-center',
  }
  const divCSSOptions = {
    buySide: `text-sm ${item.isCurrent && 'font-bold'}`,
    sellSide: `text-center text-xs tracking-tighter ${item.isCurrent && 'font-bold'}`,
  }
  const buttonDivCSSOptions = {
    buySide: 'mobile-padding-bottom flex shrink-0 grow basis-1/4 cursor-pointer flex-col items-center',
    sellSide: 'mobile-padding-bottom p-0.5',
  }

  const linkCSS = linkCSSOptions.sellSide
  const divCSS = divCSSOptions.sellSide
  const buttonCSS = buttonDivCSSOptions.sellSide

  const content = (
    <a className={linkCSS} data-testid={`nav-mobile-${item.name}`}>
      <Illustration
        asset={item.illustration}
        customColour={!item.isCurrent ? 'muted' : undefined}
        className="mb-2"
        size={24}
      />
      {item.key === 'intercom' && <NewMessagesBubble />}
      <div className={divCSS}>{item.name}</div>
    </a>
  )

  return item?.url ? (
    <Link href={item.url} key={item.name} passHref>
      {content}
    </Link>
  ) : item?.callback ? (
    <div role="button" className={buttonCSS} onClick={item.callback}>
      {content}
    </div>
  ) : (
    <></>
  )
}

export interface MenuItem {
  key: string
  name: string
  url?: string
  callback?: () => void
  illustration: Illustrations
  isExternalLink?: boolean
  visible: boolean
  isCurrent: boolean
}

export interface SidebarItemProps {
  item: MenuItem
}

const SidebarItem = ({ item }: PropsWithChildren<SidebarItemProps>) => {
  const content = (
    <a
      data-testid={`nav-${item.name}`}
      className={classNames(
        'flex p-4 border-b border-b-text-100 duration-200 transition-colors hover:opacity-50 transition-all relative',
        item.isCurrent ? 'font-semibold bg-light' : 'font-medium text-muted'
      )}
    >
      <div className={classNames('mr-4 duration-500')}>
        <Illustration asset={item.illustration} customColour={!item.isCurrent ? 'muted' : undefined} size={20} />
      </div>
      {item.key === 'intercom' && <NewMessagesBubble />}

      {item.name}
    </a>
  )

  return (
    (item?.url && (
      <Link href={item.url} key={item.name} passHref>
        {content}
      </Link>
    )) ||
    (item?.callback && (
      <div role="button" className="cursor-pointer" onClick={item.callback}>
        {content}
      </div>
    )) || <></>
  )
}
