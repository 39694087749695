import { toast } from 'react-hot-toast'
import { startAppListening } from '@redux/listeners'
import { fireToastNotifications } from '@redux/reducers/notifications'

// By using store events, toast notifications are fired from Redux's central store, this means they complete their full life cycle and don't continue to flicker on every page load (previous bug)
export const notificationsListeners = () => {
  startAppListening({
    actionCreator: fireToastNotifications,
    effect: async (action) => {
      toast.dismiss()
      const optimisticUIUpdate = action.payload.optimisticUIUpdate
      const onlyShowError = action.payload.onlyShowError

      if (optimisticUIUpdate) {
        toast.success(action.payload.successMessage || 'Success')
        action.payload.result
          .then((data) => data)
          .catch(() => {
            toast.error(action.payload.errorMessage || 'Error, please try again')
          })

        return
      }

      if (onlyShowError) {
        action.payload.result.then((result) => {
          const resultError = result?.error
          if (resultError) {
            toast.error(action.payload.errorMessage || 'Error, please try again')
          }
        })
        return
      }

      const transformedReduxResultPromise = new Promise((resolve, reject) => {
        action.payload.result
          .then((result) => {
            const resultError = result?.error
            if (resultError) {
              reject(resultError)
            }
            resolve(result)
          })
          .catch((err) => reject(err))
      })
      toast.promise(transformedReduxResultPromise, {
        loading: 'Loading',
        success: action.payload.successMessage || 'Success',
        error: action.payload.errorMessage || 'Error, please try again',
      })
    },
  })
}
