import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useAppSelector } from '@redux/hooks'
import { setStorageItem } from '@utils/storage'

const initialState = {
  auth0id: '',
  emojiFeedback: {
    Sad: false,
    Ok: false,
    Happy: false,
  },
}

interface Payload {
  emoji: string
  auth0id: string
}

export const FEEDBACK_LOCAL_STATE_KEY = 'feedbackLocalState'

export const feedback = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setEmojiFeedback: (_, { payload }: PayloadAction<Payload>) => {
      const newState = {
        auth0id: payload.auth0id,
        emojiFeedback: {
          ...initialState.emojiFeedback,
          [payload.emoji]: true,
        },
      }
      setStorageItem(`${FEEDBACK_LOCAL_STATE_KEY}_${payload.auth0id}`, JSON.stringify(newState))
      return newState
    },
  },
})

export const useEmojiFeedbackSelector = () =>
  useAppSelector((state) => Object.entries(state.feedback.emojiFeedback ?? {}).find(([, val]) => val)?.[0] || '')

export const { setEmojiFeedback } = feedback.actions
export default feedback.reducer
