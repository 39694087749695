import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from '@redux/hooks'
import { initAnalytics, setBypassDefaultPageView, setPageReady, updateAnalyticsTitle } from '@redux/reducers/analytics'
import { store } from '@redux/store'

export const useSetupAnalytics = ({
  pageTitle,
  bypassDefaultPageView,
}: {
  pageTitle: string
  bypassDefaultPageView: boolean | undefined
}) => {
  const router = useRouter()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(initAnalytics())
  }, [])

  useEffect(() => {
    dispatch(setBypassDefaultPageView(!!bypassDefaultPageView))
  }, [bypassDefaultPageView])

  useEffect(() => {
    dispatch(updateAnalyticsTitle(pageTitle))
  }, [pageTitle])

  const resetPageReadyHandler = useCallback(() => {
    dispatch(setPageReady(false))
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', resetPageReadyHandler)

    return () => {
      router.events.on('routeChangeStart', resetPageReadyHandler)
    }
  }, [])

  useEffect(() => {
    // we're hitting the store directly as we *don't* want to subscribe with a selector - it will cause an infinite rerender loop
    const pageIsImmediatelyReady = !store.getState().analytics.bypassDefaultPageView

    if (pageIsImmediatelyReady) store.dispatch(setPageReady(true))
  }, [router.asPath])
}
