import { KeyDate, LenderCustomer, LenderCustomerProperty } from '@home-in/models'
import { formatPhoneNumber } from 'react-phone-number-input'
import { sentenceCase } from 'sentence-case'
import { LenderCustomerJourneyResponse } from '@redux/apis/lenders'

export const displayKeyDateType = new Map<KeyDate, string>([
  ['property_added_date', 'Added property on'],
  ['invitation_date', 'Invited on'],
  ['auction_date', 'Auction on'],
  ['contract_advice_requested_date', 'Contract advice requested on'],
  ['building_and_pest_inspection_date', 'Building and pest inspection on'],
  ['contract_exchanged_date', 'Contract exchanged on'],
  ['pexa_invitation_date', 'Pexa invitation on'],
  ['settlement_date', 'Settlement date'],
])

//TODO: ideally we should consolidate this in platypus or update the API to return the camelCase type
export const mapKeyDateType = new Map<KeyDate, string>([
  ['property_added_date', 'propertyAddedDateTime'],
  ['invitation_date', 'invitationDateTime'],
  ['auction_date', 'auctionDateTime'],
  ['contract_advice_requested_date', 'contractAdviceRequestedDateTime'],
  ['building_and_pest_inspection_date', 'buildingAndPestInspectionDateTime'],
  ['contract_exchanged_date', 'contractExchangedDateTime'],
  ['pexa_invitation_date', 'pexaInvitationDateTime'],
  ['settlement_date', 'settlementDateTime'],
])

export const customerJourneyMilestones: {
  name: string
  dateKey: keyof LenderCustomerProperty
  conditional?: boolean
}[] = [
  { name: 'Added property', dateKey: 'propertyAddedDateTime' },
  { name: 'Auction date', dateKey: 'auctionDateTime', conditional: true },
  { name: 'Contract advice requested', dateKey: 'contractAdviceRequestedDateTime' },
  { name: 'Building and pest inspection date', dateKey: 'buildingAndPestInspectionDateTime' },
  { name: 'Exchanged contract', dateKey: 'contractExchangedDateTime' },
  { name: 'Pexa invitation sent', dateKey: 'pexaInvitationDateTime' },
  { name: 'Settlement date', dateKey: 'settlementDateTime' },
]

export interface LenderCustomerItemProps {
  id: string
  name: string
  email: string
  phone: string
  status: string
  keyDateLabel?: string
  keyDateValue?: string
  chl?: string
}

export const displayName = (customer: LenderCustomer | LenderCustomerJourneyResponse): string => {
  const { firstName, lastName } = customer
  if (!firstName && !lastName) return '(no name)'
  return !firstName ? lastName : !lastName ? firstName : `${firstName} ${lastName}`
}

export const displayLenderCustomers = (customers: LenderCustomer[]): LenderCustomerItemProps[] => {
  return customers.map((customer) => {
    return {
      id: customer.customerId,
      name: displayName(customer),
      email: customer.email,
      phone: customer.phone,
      status: sentenceCase(customer.status),
      keyDateLabel: customer.keyDateType ? displayKeyDateType.get(customer.keyDateType) : undefined,
      keyDateValue: customer.keyDateValue ? new Date(customer.keyDateValue).toLocaleDateString('en-GB') : undefined,
      chl: customer.latestChl || undefined,
    }
  })
}

export const displayPhoneNumber = (phone: string): string => {
  if (!phone) return ''
  return formatPhoneNumber(phone) || phone?.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
}
