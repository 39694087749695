import { EnhancedStore } from '@reduxjs/toolkit'
import { setStorageItem } from '@utils/storage'

export const setupSubscriber = (store: EnhancedStore) => {
  let timer: NodeJS.Timeout

  const debounce = (call: () => void, timeout: number) => {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(call, timeout)
  }

  store.subscribe(() => {
    debounce(() => {
      setStorageItem('applicationState', JSON.stringify(store.getState()), true, 86400000) // set 1 day expiry
    }, 5000)
  })
}
