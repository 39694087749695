import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PageState {
  title: string | undefined
  subtitle: string | undefined
  route: string | undefined
  routerPopped: boolean
}

const initialState: PageState = {
  title: undefined,
  subtitle: undefined,
  route: undefined,
  routerPopped: false,
}

export const page = createSlice({
  name: 'page',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<Partial<PageState>>) => {
      return { ...state, ...payload }
    },
    clearPage: (_) => {
      return initialState
    },
    clearTitle: (state) => {
      return { ...state, title: undefined }
    },
    clearSubtitle: (state) => {
      return { ...state, subtitle: undefined }
    },
    setRouterPopped: (state) => {
      return { ...state, routerPopped: true }
    },
    clearRouterPopped: (state) => {
      return { ...state, routerPopped: false }
    },
  },
})

export const { updatePage, clearSubtitle, clearTitle, clearPage, setRouterPopped, clearRouterPopped } = page.actions
export default page.reducer
