import MaterialUIButton, { ButtonOwnProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { HTMLAttributes, PropsWithChildren, useState } from 'react'
import { Icon, Icons } from '@elements/icons/icon'
import { useSellUiChanges } from '@hooks/useSellUIChanges'
import { classNames } from '@utils/helpers/classNameHelper'
import LoadingSpinner from './loading-spinner'
import type { ButtonHTMLAttributes } from 'react'

export type ButtonStyle = keyof typeof buttonStyle
export type ButtonSize = keyof typeof buttonSize

enum buttonStyle {
  primary,
  secondary,
  secondary_alt,
  gradient,
}

enum buttonSize {
  sm,
  md,
  lg,
  full,
  dynamic,
}

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  link?: string
  callback?: () => any
  disabled?: boolean
  category?: ButtonStyle
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  size?: ButtonSize
  muiSize?: ButtonOwnProps['size']
  name?: string
  isLoading?: boolean
  variant?: 'outlined' | 'contained'
  color?: 'primary' | 'secondary'
  disableElevation?: boolean
  icon?: Icons
  overrideFullWidth?: boolean
  overrideDefaultWidth?: boolean
  rounded?: boolean
  form?: string
}

const translateCategoryToVariant = (category: string) => {
  switch (category) {
    case 'primary':
      return 'contained'
    case 'secondary':
    case 'secondary_alt':
      return 'outlined'
    default:
      return 'contained'
  }
}

const buttonSizeStyleMapping = (size: keyof typeof buttonSize) => ({
  'min-w-0': size === 'md',
  'min-w-[12.5rem]': size === 'md',
  'min-w-[16.5rem]': size === 'lg',
  'w-full': size === 'full',
  'md:min-w-[12.5rem] md:w-auto w-full': size === 'dynamic',
})

const buttonStyleMapping = (category: keyof typeof buttonStyle) => ({
  'bg-gradient-to-r from-[#017F76] to-[#00385B]': category === 'gradient',
})

export const Button = ({
  callback,
  children,
  disabled = false,
  category = 'primary',
  type = 'button',
  size = 'md',
  muiSize = undefined,
  className,
  link,
  name,
  isLoading = false,
  icon = undefined,
  overrideFullWidth = false,
  overrideDefaultWidth = false,
  rounded = false,
  form,
}: PropsWithChildren<ButtonProps>) => {
  const { enableMaterialUiForms } = useFlags()
  const uiChangesFromSell = useSellUiChanges()
  const showMaterialUI = uiChangesFromSell || enableMaterialUiForms
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  if (link) {
    return showMaterialUI ? (
      <MaterialUIButton
        data-testid={`button-${name}`}
        className={classNames(buttonStyleMapping(category))}
        fullWidth={isSmallScreen || overrideFullWidth}
        type={type}
        disableElevation
        variant={translateCategoryToVariant(category)}
        onClick={callback}
        disabled={disabled || isLoading}
        size={muiSize || 'large'}
        sx={{
          '&.MuiButton-root': {
            ...(!overrideDefaultWidth && { minWidth: '12.5rem' }),
            ...(rounded && { borderRadius: '21px' }),
          },
        }}
        form={form}
      >
        <a href={link} target="_blank" rel="noreferrer">
          {isLoading ? (
            <CircularProgress size={24} />
          ) : icon ? (
            <div className="flex">
              {icon && <Icon asset={icon} size={24} className="mr-4 shrink-0" />}
              {children}
            </div>
          ) : (
            children
          )}
        </a>
      </MaterialUIButton>
    ) : (
      <button
        className={classNames(
          'py-3 px-7 w-auto rounded text-center text-lg duration-200 font-bold h6 disabled:bg-background-dark-50 disabled:text-background-dark-200 disabled:border-background-dark-50 disabled:cursor-not-allowed outline-2 outline-transparent outline-offset-2 focus:outline-secondary/50',
          {
            'bg-primary border border-primary hover:bg-primary-400 active:bg-primary-300': category === 'primary',
            'bg-white border border-secondary-variant text-secondary-variant hover:bg-secondary-variant-100 active:bg-secondary-variant-200':
              category === 'secondary',
            'bg-secondary-variant border border-secondary-variant hover:bg-secondary-variant-400 active:bg-secondary-variant-300 text-white':
              category === 'secondary_alt',
            ...buttonSizeStyleMapping(size),
          },
          className
        )}
        disabled={disabled}
        type={type}
        onClick={callback}
        data-testid={`button-${name}`}
        form={form}
      >
        <a href={link} target="_blank" rel="noreferrer">
          {children}
        </a>
      </button>
    )
  }
  return showMaterialUI ? (
    <div className={classNames('relative', className)}>
      <MaterialUIButton
        data-testid={`button-${name}`}
        className={classNames(buttonStyleMapping(category))}
        fullWidth={isSmallScreen || overrideFullWidth}
        type={type}
        disableElevation
        variant={translateCategoryToVariant(category)}
        onClick={callback}
        disabled={disabled || isLoading}
        size={muiSize || 'large'}
        sx={{
          '&.MuiButton-root': {
            ...(!overrideDefaultWidth && !muiSize && { minWidth: '12.5rem' }),
            ...(rounded && { borderRadius: '21px' }),
          },
        }}
        form={form}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : icon ? (
          <div className="flex">
            {icon && <Icon asset={icon} size={24} className="mr-4 shrink-0" />}
            {children}
          </div>
        ) : (
          children
        )}
      </MaterialUIButton>
    </div>
  ) : (
    <button
      className={classNames(
        'py-3 px-7 w-auto rounded text-center text-lg duration-200 font-bold h6 disabled:bg-background-dark-50 disabled:text-background-dark-200 disabled:border-background-dark-50 disabled:cursor-not-allowed outline-2 outline-transparent outline-offset-2 focus:outline-secondary/50',
        {
          'bg-primary border border-primary hover:bg-primary-400 active:bg-primary-300': category === 'primary',
          'bg-white border border-secondary-variant text-secondary-variant hover:bg-secondary-variant-100 active:bg-secondary-variant-200':
            category === 'secondary',
          'bg-secondary-variant border border-secondary-variant hover:bg-secondary-variant-400 active:bg-secondary-variant-300 text-white':
            category === 'secondary_alt',
          ...buttonSizeStyleMapping(size),
        },
        className
      )}
      disabled={disabled}
      type={type}
      onClick={callback}
      data-testid={`button-${name}`}
      form={form}
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        children
      )}
    </button>
  )
}
