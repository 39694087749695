import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useAppSelector } from '@redux/hooks'

interface UseGetUtmIdOptions {
  source: 'url' | 'store'
}

export const isUtmUnloan = (utmId?: string) => !!utmId?.toLowerCase().includes('unloan')

export const useGetUtmId = ({ source }: UseGetUtmIdOptions) => {
  const router = useRouter()
  const storeUtmId = useAppSelector((state) => state.app.utmId)
  const cookieUtmId = Cookies.get('utm_id')

  if (source === 'url') {
    const queryUtmId = router.query?.utm_id as string
    return queryUtmId?.length ? queryUtmId : cookieUtmId
  } else {
    return storeUtmId ?? cookieUtmId
  }
}
