import React, { useEffect } from 'react'
import { DeviceInfo } from '@utils/helpers/analytics.interface'

declare global {
  interface Window {
    ReactNativeWebView: any | undefined
    _platform: string | undefined
    _deviceInfo: DeviceInfo
  }
}

//this component will send and receive data from react native webview
export const AppDataBridge = () => {
  //let the RN app know the app is ready
  useEffect(() => {
    if (!window?.ReactNativeWebView) return
    window.ReactNativeWebView.postMessage('appReady')
  }, [])

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== '') return
      try {
        const data = JSON.parse(event.data)
        //no data needed for now
      } catch {}
    }
    window.addEventListener('message', handleMessage)

    return () => window.removeEventListener('message', handleMessage)
  })

  return <></>
}

export const dispatchDataToMammoth = (data: string) => {
  if (!window?.ReactNativeWebView) return
  window.ReactNativeWebView.postMessage(data)
}

export const isAppOnMobilePlatform = () => {
  return window?._platform === 'mobile'
}
