import { ErrorSeverity } from '@home-in/models'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Button } from '@elements/button'
import { SupportEmailLink } from '@elements/forms/link'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { trackButtonClick, trackClientError } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { sendClientLog } from '@utils/helpers/error.helpers'

interface ErrorProps {
  error: Error
  stackTrace?: string
  severity: ErrorSeverity
  code?: string
  subheading?: string
  message?: string
  showButtons?: boolean
  buttonType?: 'generic' | 'exception'
  showGoToHomepageButton?: boolean
  taskError?: boolean
}

export const Error = ({
  error,
  stackTrace,
  severity,
  code,
  message,
  subheading,
  showButtons = true,
  buttonType = 'generic',
  showGoToHomepageButton = true,
  taskError = false,
}: ErrorProps) => {
  const router = useRouter()
  const auth0id = useAppSelector((state) => state.auth.user?.sub) || ''
  const dispatch = useAppDispatch()

  const route = router.asPath

  useEffect(() => {
    sendClientLog({
      auth0id,
      host: window?.location?.host,
      message: error.toString(),
      route,
      severity,
      stackTrace,
      error,
    })

    dispatch(
      trackClientError({
        action: code ?? 'unknown',
        value: {
          message: error.toString(),
          route,
          stackTrace,
        },
      })
    )
  }, [])

  const handleClick = (action: string, callback: () => void) => {
    dispatch(
      trackButtonClick({
        action,
        category: AnalyticsCategories.ClientError,
      })
    )
    callback()
  }

  return (
    <div className={`mx-6 ${!taskError && 'mt-40'} text-center md:mx-0`}>
      {code && (
        <div>
          <h1 className="text-2xl md:text-3xl">{code}</h1>
        </div>
      )}
      {subheading && <h1 className="text-xl">{subheading}</h1>}
      {message && (
        <div className="mt-8">
          <p>{message}</p>
          <p>
            If the issue persists, please contact <SupportEmailLink />
          </p>
        </div>
      )}
      {showButtons && (
        <div className="mt-10 flex flex-col items-center justify-center space-y-2 md:flex-row md:space-y-0 md:space-x-5">
          {buttonType === 'exception' ? (
            <>
              <Button callback={() => handleClick('Reload', () => router.reload())} className="w-full md:max-w-[15rem]">
                Reload
              </Button>
              {showGoToHomepageButton && (
                <Button
                  callback={() => handleClick('Go to homepage', () => router.push('/'))}
                  category="secondary"
                  className="w-full md:max-w-[15rem]"
                >
                  Go to homepage
                </Button>
              )}
            </>
          ) : (
            <>
              {showGoToHomepageButton && (
                <Button
                  callback={() => handleClick('Go to homepage', () => router.push('/'))}
                  className="w-full md:max-w-[15rem]"
                >
                  Go to homepage
                </Button>
              )}
              <Button
                className="w-full md:max-w-[15rem]"
                category="secondary"
                callback={() => handleClick('Previous page', () => router.back())}
              >
                Previous page
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  )
}
