import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const lenderCustomerJourney = createSlice({
  name: 'lenderCustomerJourney',
  initialState: [],
  reducers: {
    setLenderCustomerJourney: (state, action: PayloadAction<any>) => {
      return action.payload || []
    },
  },
})

export const { setLenderCustomerJourney } = lenderCustomerJourney.actions
export default lenderCustomerJourney.reducer
