import { Actor, SELL_SIDE_TASKS, SellChecklistTaskId, State, TaskType } from '@home-in/models'
import { CategoryIds, TaskInfo } from '@features/checklist/data/checklist-data-types'

interface GetTaskIdsToInformationProps {
  contractSigned: boolean
  state: State
}

// This information is exclusively used to build the checklist.
// If you need this information please use the useTaskInformationFromChecklist() hook
export const taskInformation = ({ contractSigned, state }: GetTaskIdsToInformationProps): TaskInfo[] => [
  // Finance & Ownership
  {
    title: 'Estimate Purchase Price',
    categoryId: CategoryIds.financeAndOwnership,
    taskIds: ['ACT0003'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: ['KC-71'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'BC10',
  },
  {
    title: 'Consider Costs',
    categoryId: CategoryIds.financeAndOwnership,
    taskIds: ['ACT0034'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    type: TaskType.CONFIRMATION,
    guidanceArticles: [
      'KC-112',
      'KC-113',
      'KC-119',
      'KC-120',
      'KC-126',
      'KC-127',
      'KC-16',
      'KC-43',
      'KC-44',
      'KC-60',
      'KC-66',
      'KC-69',
      'KC-71',
      'KC-92',
      'KC-93',
      'KC-94',
      'KC-95',
    ],
    staticTaskReference: 'BC11',
  },
  {
    title: 'Confirm Purchasing Details',
    categoryId: contractSigned ? CategoryIds.contractReview : CategoryIds.financeAndOwnership,
    taskIds: ['ACT0057', 'ACT0195'],
    actor: Actor.CUSTOMER,
    categorySortOrder: contractSigned ? 0.5 : 3,
    guidanceArticles: ['KC-113', 'KC-120', 'KC-16', 'KC-44', 'KC-6', 'KC-64', 'KC-66', 'KC-68', 'KC-94', 'KC-95'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'BC12',
  },
  // Property Checks
  {
    title: 'Inspect the property',
    categoryId: CategoryIds.propertyChecks,
    taskIds: ['ACT0019', 'ACT0116', 'ACT0173', 'ACT0180'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: ['KC-110', 'KC-118', 'KC-125', 'KC-41', 'KC-77', 'KC-18', 'KC-88', 'KC-89'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'IP20',
  },
  {
    title: 'Obtain property reports',
    categoryId: CategoryIds.propertyChecks,
    taskIds: ['ACT0035', 'ACT0117', 'ACT0170', 'ACT0184'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: ['KC-11', 'KC-124', 'KC-109', 'KC-40', 'KC-87'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'IP30',
  },
  {
    title: 'Review due diligence checklist',
    categoryId: CategoryIds.propertyChecks,
    taskIds: ['ACT0113'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 3,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'IP40',
  },
  // Contract Review
  {
    title: 'Request Contract Review',
    categoryId: CategoryIds.contractReview,
    taskIds: ['ACT0023', 'ACT0185'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: [
      'KC-108',
      'KC-117',
      'KC-30',
      'KC-39',
      'KC-55',
      'KC-57',
      'KC-59',
      'KC-86',
      'KC-98',
      'KC-81',
      'KC-97',
    ],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CL10',
  },
  {
    title: 'Provide Contract of Sale',
    categoryId: CategoryIds.contractReview,
    // 'ACT0030' has been intentionally removed it is a duplicate task where you already have request contract review
    taskIds: ['ACT0186', 'ACT0109'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 3,
    guidanceArticles: ['KC-10', 'KC-39', 'KC-59'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CL20',
  },
  {
    title: 'Prepare Contract Advice',
    categoryId: CategoryIds.contractReview,
    taskIds: ['ACT0190'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 4,
    guidanceArticles: ['KC-45', 'KC-67', 'KC-9', 'KC-97', 'KC-98'],
    type: TaskType.INFORMATION,
    staticTaskReference: 'CL30',
  },
  // Unsure why the title is different for otherwise identical tasks. You can probably make them the same in the future.
  {
    title: 'Prepare Legal Advice',
    categoryId: CategoryIds.contractReview,
    taskIds: ['ACT0042'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 4,
    guidanceArticles: ['KC-45', 'KC-67', 'KC-9', 'KC-97', 'KC-98'],
    type: TaskType.INFORMATION,
    staticTaskReference: 'CL30',
  },
  {
    title: 'Review advice',
    categoryId: CategoryIds.contractReview,
    taskIds: ['ACT0001', 'ACT0150', 'ACT0198'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 5,
    guidanceArticles: [
      'KC-16',
      'KC-21',
      'KC-44',
      'KC-45',
      'KC-49',
      'KC-6',
      'KC-68',
      'KC-9',
      'KC-94',
      'KC-95',
      'KC-97',
      'KC-113',
      'KC-115',
      'KC-7',
      'KC-66',
      'KC-67',
      'KC-75',
    ],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CL40',
  },
  {
    title: 'Review contract terms',
    categoryId: CategoryIds.contractReview,
    taskIds: ['ACT0191', 'ACT0192'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 6,
    guidanceArticles: [
      'KC-16',
      'KC-21',
      'KC-44',
      'KC-45',
      'KC-49',
      'KC-6',
      'KC-68',
      'KC-9',
      'KC-94',
      'KC-97',
      'KC-98',
      'KC-66',
      'KC-67',
      'KC-75',
    ],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CL40',
  },
  {
    title: 'Request contract changes',
    categoryId: CategoryIds.contractReview,
    taskIds: ['ACT0033', 'ACT0151', 'ACT0193'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 7,
    guidanceArticles: [],
    type: TaskType.INFORMATION,
    staticTaskReference: 'CL50',
  },
  {
    title: 'Request and confirm contract changes',
    categoryId: CategoryIds.contractReview,
    taskIds: ['ACT0220'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 7,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'CL51',
  },
  {
    title: 'Confirm contract terms',
    categoryId: CategoryIds.contractReview,
    taskIds: ['ACT0029', 'ACT0194'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 8,
    guidanceArticles: [
      'KC-113',
      'KC-12',
      'KC-120',
      'KC-127',
      'KC-16',
      'KC-44',
      'KC-49',
      'KC-66',
      'KC-75',
      'KC-94',
      'KC-95',
      'KC-99',
    ],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CL60',
  },
  // Offer and signing
  {
    title: 'Negotiate price',
    categoryId: CategoryIds.offerAndSigning,
    taskIds: ['ACT0007', 'ACT0043', 'ACT0174', 'ACT0181', 'ACT0206'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: ['KC-122', 'KC-46', 'KC-64', 'KC-129', 'KC-102', 'KC-101'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'CP10',
  },
  // Private treaty version of deposit and documeents
  {
    title: 'Prepare deposit and documents',
    categoryId: CategoryIds.offerAndSigning,
    taskIds: ['ACT0008', 'ACT0044', 'ACT0114', 'ACT0182'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: ['KC-12', 'KC-22', 'KC-64', 'KC-74', 'KC-99', 'KC-102', 'KC-46'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'CP30',
  },
  {
    title: 'Prepare for signing',
    categoryId: CategoryIds.offerAndSigning,
    taskIds: ['ACT0176', 'ACT0203', 'ACT0208'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 3,
    guidanceArticles: ['KC-129', 'KC-74', 'KC-122', 'KC-22', 'KC-99', 'KC-100', 'KC-101'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'CP30',
  },
  {
    title: 'Sign and send contract',
    categoryId: CategoryIds.offerAndSigning,
    taskIds: ['ACT0028', 'ACT0047', 'ACT0115', 'ACT0152', 'ACT0175'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 4,
    guidanceArticles: [
      'KC-109',
      'KC-12',
      'KC-122',
      'KC-40',
      'KC-64',
      'KC-99',
      'KC-101',
      'KC-87',
      'KC-102',
      'KC-129',
      'KC-46',
    ],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CP71',
  },
  // Auction
  {
    title: 'Plan for auction',
    categoryId: CategoryIds.auction,
    taskIds: ['ACT0018', 'ACT0046'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: ['KC-32'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'CP40',
  },
  // This version of 'prepare depsoit and documents' is for auction
  {
    title: 'Prepare deposit and documents',
    categoryId: CategoryIds.auction,
    taskIds: ['ACT0041', 'ACT0050'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: ['KC-100', 'KC-22', 'KC-32', 'KC-74'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'CP50',
  },
  {
    title: 'Attend auction and send contract',
    categoryId: CategoryIds.auction,
    taskIds: ['ACT0009', 'ACT0045'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 3,
    guidanceArticles: ['KC-32'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CP70',
  },
  {
    title: 'Attend auction and send contract',
    categoryId: CategoryIds.auction,
    taskIds: ['ACT0039', 'ACT0049'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 3,
    guidanceArticles: ['KC-32'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CP52',
  },
  // Cooling Off Period
  {
    title: 'Proceed with purchase',
    categoryId: CategoryIds.coolingOffPeriod,
    taskIds: ['ACT0052'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: ['KC-11', 'KC-64'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'CO07',
  },
  // Conditional contract terms
  {
    title: 'Consider cooling-off period',
    categoryId: CategoryIds.conditionalContractTerms,
    taskIds: ['ACT0112', 'ACT0153', 'ACT0169', 'ACT0179', 'ACT0200', 'ACT0211'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: [
      'KC-64',
      'KC-129',
      'KC-40',
      'KC-74',
      'KC-102',
      'KC-22',
      'KC-122',
      'KC-46',
      'KC-100',
      'KC-101',
      'KC-87',
    ],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'CO12',
  },
  {
    title: 'Confirm state of property',
    categoryId: CategoryIds.conditionalContractTerms,
    taskIds: ['ACT0110'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: ['KC-104', 'KC-124', 'KC-40', 'KC-87'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CO10',
  },
  {
    title: 'Confirm subject to sale',
    categoryId: CategoryIds.conditionalContractTerms,
    taskIds: ['ACT0159', 'ACT0172', 'ACT0202'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 3,
    guidanceArticles: ['KC-104'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CO13',
  },
  {
    title: state === 'WA' ? 'Obtain unconditional loan approval' : 'Obtain loan approval',
    categoryId: CategoryIds.conditionalContractTerms,
    taskIds: ['ACT0111'],
    actor: Actor.FINANCIAL_INSTITUTION,
    categorySortOrder: 4,
    guidanceArticles: ['KC-72', 'KC-74'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CO11',
  },
  {
    title: 'Subject to finance',
    categoryId: CategoryIds.conditionalContractTerms,
    taskIds: ['ACT0219'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 5,
    guidanceArticles: [],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'CO14',
    dynamicData: true,
  },
  // Property Insurance
  {
    title: 'Get home and contents insurance',
    categoryId: CategoryIds.propertyInsurance,
    taskIds: ['ACT0021', 'ACT0118', 'ACT0156', 'ACT0177', 'ACT0183', 'ACT0207'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'IN10',
  },
  {
    title: 'Consider other insurances',
    categoryId: CategoryIds.propertyInsurance,
    taskIds: ['ACT0016'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'IN20',
  },
  // Home loan
  {
    title: 'Add lender details',
    categoryId: CategoryIds.homeLoan,
    taskIds: ['ACT0215'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: [],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'FF75',
  },
  {
    title: 'Prepare loan documents',
    categoryId: CategoryIds.homeLoan,
    taskIds: ['ACT0006'],
    actor: Actor.FINANCIAL_INSTITUTION,
    categorySortOrder: 2,
    guidanceArticles: ['KC-72'],
    type: TaskType.INFORMATION,
    staticTaskReference: 'FF80',
  },
  {
    title: 'Prepare loan documents',
    categoryId: CategoryIds.homeLoan,
    taskIds: ['ACT0053'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: ['KC-72'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'FF80',
  },
  {
    title: 'Confirm home financing',
    categoryId: CategoryIds.homeLoan,
    taskIds: ['ACT0055'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 3,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'FF110',
  },
  {
    title: 'Sign loan documents',
    categoryId: CategoryIds.homeLoan,
    taskIds: ['ACT0011'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 5,
    guidanceArticles: ['KC-72'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'FF90',
  },
  {
    title: 'Finalise loan',
    categoryId: CategoryIds.homeLoan,
    taskIds: ['ACT0036'],
    actor: Actor.FINANCIAL_INSTITUTION,
    categorySortOrder: 6,
    guidanceArticles: ['KC-130', 'KC-48', 'KC-65'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'FF100',
  },
  {
    title: 'Finalise loan',
    categoryId: CategoryIds.homeLoan,
    taskIds: ['ACT0054'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 6,
    guidanceArticles: ['KC-130', 'KC-48', 'KC-65'],
    type: TaskType.INFORMATION,
    staticTaskReference: 'FF100',
  },
  // Priority Settlement Tasks
  {
    title: 'Check first home buyer benefits',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0157', 'ACT0158', 'ACT0171', 'ACT0178', 'ACT0187', 'ACT0199', 'ACT0204', 'ACT0210'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: ['KC-13', 'KC-16', 'KC-127', 'KC-66', 'KC-95', 'KC-120', 'KC-94', 'KC-113', 'KC-114'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PS50',
  },
  {
    title: 'Check home buyer benefits',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0214'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: ['KC-113', 'KC-114'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'PS51',
  },
  {
    title: 'Verify your identity',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0037', 'ACT0205', 'ACT0209', 'ACT0213'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: ['KC-63', 'KC-123'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'PS20',
  },
  {
    title: 'Confirm buyer verification declaration',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0196'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2.1,
    guidanceArticles: ['KC-113'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'PS200',
  },
  {
    title: 'Complete transfer duty form',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0201'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2.2,
    guidanceArticles: [],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'PS250',
  },
  {
    title: 'Complete searches',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0010', 'ACT0103'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 3,
    guidanceArticles: ['KC-108', 'KC-30', 'KC-57', 'KC-117', 'KC-55', 'KC-81', 'KC-86'],
    type: TaskType.INFORMATION,
    staticTaskReference: 'PS40',
  },
  // TODO: lots of guesses here couldn't place these tasks in any checklists for NSW
  {
    title: 'Sign and send transfer document',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0212'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 4,
    guidanceArticles: [],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'PS15',
  },
  // TODO: No markdown or assignment task for these two. Investigate.
  // {
  //   title: 'Prepare transfer of land',
  //   categoryId: CategoryIds.prioritySettlementTasks,
  //   taskIds: ['ACT0104'],
  //   actor: Actor.CONVEYANCER,
  //   categorySortOrder: 5,
  //   guidanceArticles: [],
  // },
  {
    title: 'Complete purchaser declaration',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0015'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2.22,
    guidanceArticles: ['KC-14', 'KC-4', 'KC-6', 'KC-69'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'PS45',
  },
  {
    title: 'Complete duty declaration',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0160'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2.23,
    guidanceArticles: ['KC-6', 'KC-92', 'KC-96'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'PS45',
  },
  // TODO: No markdown or assignment task for this one. Investigate.
  // {
  //   title: 'Confirm transfer document contents',
  //   categoryId: CategoryIds.prioritySettlementTasks,
  //   taskIds: ['ACT0024'],
  //   actor: Actor.CUSTOMER,
  //   categorySortOrder: 2.24,
  //   guidanceArticles: [],
  // },
  {
    title: 'Arrange final inspection',
    categoryId: CategoryIds.prioritySettlementTasks,
    taskIds: ['ACT0002', 'ACT0154'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 12,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PS140',
  },
  // Move planning
  {
    title: 'Arrange property management',
    categoryId: CategoryIds.movePlanning,
    taskIds: ['ACT0218'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 1,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PM05',
  },

  {
    title: 'Arrange mail re-direct',
    categoryId: CategoryIds.movePlanning,
    taskIds: ['ACT0014'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PM10',
  },
  {
    title: 'Arrange your move',
    categoryId: CategoryIds.movePlanning,
    taskIds: ['ACT0031'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 3,
    guidanceArticles: ['KC-26'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PM20',
  },
  {
    title: 'Arrange utilities',
    categoryId: CategoryIds.movePlanning,
    taskIds: ['ACT0013'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 4,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PM40',
  },
  {
    title: 'Arrange internet',
    categoryId: CategoryIds.movePlanning,
    taskIds: ['ACT0017'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 5,
    guidanceArticles: [],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PM30',
  },
  // Settlement Funds
  {
    title: 'Provide draft settlement statement',
    categoryId: CategoryIds.settlementFunds,
    taskIds: ['ACT0026'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 1,
    guidanceArticles: [
      'KC-112',
      'KC-119',
      'KC-126',
      'KC-130',
      'KC-43',
      'KC-48',
      'KC-60',
      'KC-65',
      'KC-69',
      'KC-92',
      'KC-93',
    ],
    type: TaskType.INFORMATION,
    staticTaskReference: 'PS75',
  },
  {
    title: 'Arrange settlement funds',
    categoryId: CategoryIds.settlementFunds,
    taskIds: ['ACT0012'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: [
      'KC-112',
      'KC-119',
      'KC-126',
      'KC-130',
      'KC-43',
      'KC-48',
      'KC-60',
      'KC-65',
      'KC-69',
      'KC-92',
      'KC-93',
    ],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PS110',
  },

  {
    title: "Await seller's confirmation",
    categoryId: CategoryIds.settlementFunds,
    taskIds: ['ACT0022'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 3,
    guidanceArticles: ['KC-130', 'KC-48', 'KC-65'],
    type: TaskType.INFORMATION,
    staticTaskReference: 'PS80',
  },
  {
    title: 'Provide final settlement statement',
    categoryId: CategoryIds.settlementFunds,
    taskIds: ['ACT0040'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 4,
    guidanceArticles: [
      'KC-112',
      'KC-119',
      'KC-126',
      'KC-130',
      'KC-43',
      'KC-48',
      'KC-60',
      'KC-65',
      'KC-69',
      'KC-92',
      'KC-93',
    ],
    type: TaskType.INFORMATION,
    staticTaskReference: 'PS100',
  },
  {
    title: 'Adjust settlement funds',
    categoryId: CategoryIds.settlementFunds,
    taskIds: ['ACT0025'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 5,
    guidanceArticles: [
      'KC-112',
      'KC-119',
      'KC-126',
      'KC-130',
      'KC-43',
      'KC-48',
      'KC-60',
      'KC-65',
      'KC-69',
      'KC-92',
      'KC-93',
    ],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PS150',
  },
  {
    title: 'Validate settlement funds',
    categoryId: CategoryIds.settlementFunds,
    taskIds: ['ACT0005'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 6,
    guidanceArticles: [
      'KC-112',
      'KC-119',
      'KC-126',
      'KC-130',
      'KC-43',
      'KC-48',
      'KC-60',
      'KC-65',
      'KC-69',
      'KC-92',
      'KC-93',
    ],
    type: TaskType.INFORMATION,
    staticTaskReference: 'PS120',
  },
  // Settlement
  {
    title: 'Book settlement',
    categoryId: CategoryIds.settlement,
    taskIds: ['ACT0004', 'ACT0102'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 1,
    guidanceArticles: ['KC-26', 'KC-7'],
    type: TaskType.INFORMATION,
    staticTaskReference: 'PS130',
  },
  {
    title: 'Attend final inspection',
    categoryId: CategoryIds.settlement,
    taskIds: ['ACT0027', 'ACT0155'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 2,
    guidanceArticles: ['KC-7'],
    type: TaskType.CONFIRMATION,
    staticTaskReference: 'PS145',
  },
  // TODO: No markdown or assignment task for these two. Investigate.
  // {
  //   title: 'Finalise settlement documents',
  //   categoryId: CategoryIds.settlement,
  //   taskIds: ['ACT0107'],
  //   actor: Actor.CONVEYANCER,
  //   categorySortOrder: 3,
  //   guidanceArticles: [],
  // },
  // {
  //   title: 'Confirm successful settlement',
  //   categoryId: CategoryIds.settlement,
  //   taskIds: ['ACT0108'],
  //   actor: Actor.CONVEYANCER,
  //   categorySortOrder: 4,
  //   guidanceArticles: [],
  // },
  {
    title: 'Pay conveyance duty',
    categoryId: CategoryIds.settlement,
    taskIds: ['ACT0197'],
    actor: Actor.CUSTOMER,
    categorySortOrder: 5,
    guidanceArticles: ['KC-112', 'KC-113'],
    type: TaskType.ASSIGNMENT,
    staticTaskReference: 'PS220',
  },
  {
    title: 'Attend settlement',
    categoryId: CategoryIds.settlement,
    taskIds: ['ACT0020'],
    actor: Actor.CONVEYANCER,
    categorySortOrder: 6,
    guidanceArticles: ['KC-23', 'KC-7'],
    type: TaskType.INFORMATION,
    staticTaskReference: 'ST10',
  },
]

export const getTaskTitleForSellSide = ({ taskId }: { taskId: SellChecklistTaskId }) => {
  return SELL_SIDE_TASKS[taskId].title
}
