import { Address, DomainProperty } from '@home-in/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AddPropertyState {
  domain_property: DomainProperty | undefined
  manual_address: Address | undefined
}

export const addProperty = createSlice({
  name: 'addProperty',
  initialState: <AddPropertyState>{
    domain_property: undefined,
    manual_address: undefined,
  },
  reducers: {
    setDomainProperty: (state, action: PayloadAction<DomainProperty>) => {
      state.domain_property = action.payload
      state.manual_address = undefined
    },
    setManualAddress: (state, action: PayloadAction<Address>) => {
      state.domain_property = undefined
      state.manual_address = action.payload
    },
    clearAddresses: (state) => {
      state.domain_property = undefined
      state.manual_address = undefined
    },
  },
})

export const { setDomainProperty, setManualAddress, clearAddresses } = addProperty.actions
export default addProperty.reducer
