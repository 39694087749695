import { ErrorSeverity } from '@home-in/models'
import React, { ErrorInfo } from 'react'
import { Error as ErrorElement } from '@elements/error'

/**
 * This error boundary catches any uncaught exceptions triggered in its children during
 * the React rendering cycle.
 *
 * Does *not* apply to async things like RTK Query calls, event handlers
 * or setTimeout callbacks
 *
 * Only class components can be error boundaries:
 * https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<
  {},
  {
    error: Error | null
    errorInfo: ErrorInfo | null
  }
> {
  constructor(props: {}) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      return (
        <ErrorElement
          error={this.state.error}
          stackTrace={this.state.errorInfo?.componentStack || this.state.error?.stack}
          severity={ErrorSeverity.ERROR}
          subheading="Something went wrong"
          message="Don't worry, you didn't break the internet! We've sent a report of this issue to our staff."
          buttonType="exception"
        />
      )
    }

    return this.props.children
  }
}
