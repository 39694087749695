import { ErrorSeverity } from '@home-in/models'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'
import { Error as ErrorElement } from '@elements/error'
import Logo from '@elements/logo'
import { Modal } from '@elements/modal'
import { LenderNavigation } from '@elements/navigation-lender'
import { FullPageLoader } from '@elements/status-handlers/full-page-loader'
import useLenderIntercomSetup from '@hooks/useLenderIntercomSetup'
import useLenderLaunchDarklySetup from '@hooks/useLenderLaunchDarklySetup'
import { useGetLenderProfileQuery } from '@redux/apis/lender-profile'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { toggleModal } from '@redux/reducers/modal'
import { classNames } from '@utils/helpers/classNameHelper'
import { ErrorBoundary } from '../../error-boundary'
import { AuthenticatedWrapperProps } from './authenticated-wrapper'

export const desktopNavLinkBannerId = 'desktop-nav-link-banner'

export const LenderAuthenticatedWrapperInner = ({
  children,
  hideNav,
  hideTopBar,
}: PropsWithChildren<AuthenticatedWrapperProps>) => {
  useLenderIntercomSetup()
  useLenderLaunchDarklySetup()
  const router = useRouter()
  const { loaded } = useAppSelector((state) => state.auth)

  // This is set to false to position the logo in the top left of the page
  const showTopNavigation = false

  const dispatch = useAppDispatch()
  const showModal = useAppSelector((state) => state.modal.active)
  const modalContent = useAppSelector((state) => state.modal.content)

  const { error: profileError } = useGetLenderProfileQuery()

  // Restricted temporarily
  const { denyLender } = useFlags()

  return denyLender ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Logo innerClassName="w-[8.5rem]" />
      <h1 className="mt-8 text-3xl font-bold">Lender App Coming Soon</h1>
      <div>
        Please contact{' '}
        <a className="underline" href="mailto:pavel.baranov@home-in.com.au">
          pavel.baranov@home-in.com.au
        </a>{' '}
        for more information
      </div>
    </div>
  ) : (
    <>
      <div id="authed-page-wrapper" className={classNames({ hidden: !loaded })}>
        <main className="mobile-safe-vh flex flex-col md:min-h-screen">
          {showTopNavigation && (
            <div
              id={desktopNavLinkBannerId}
              className="top-0 z-50 hidden h-16 w-full justify-center border-b border-b-background bg-white md:fixed md:flex"
            >
              <Link href="/" passHref>
                <a>
                  <Logo innerClassName="w-[8.5rem]" />
                </a>
              </Link>
            </div>
          )}
          <section className={classNames('flex flex-1 pb-16 bg-white md:pb-0', showTopNavigation && 'pb-0')}>
            {profileError ? (
              <div className="mx-auto">
                <Logo innerClassName="w-[8.5rem]" />
                <ErrorElement
                  error={new Error(`Unable to load ${profileError ? 'profile' : 'customers'}. Showing fallback.`)}
                  severity={ErrorSeverity.ERROR}
                  subheading="Something went wrong"
                  message="Don't worry, you didn't break the internet! We've sent a report of this issue to our staff."
                  buttonType="exception"
                  showGoToHomepageButton={false}
                />
              </div>
            ) : (
              <>
                {/* Dont show nav on `Support Inbox` on mobile */}
                {!hideNav && <LenderNavigation />}
                <div
                  className={classNames(
                    'flex-1 p-2 md:p-6 md:pt-0  md:ml-72 bg-white w-full mb-16 md:mb-0',
                    'md:mt-6',
                    hideTopBar && 'md:mt-0',
                    hideNav && 'md:ml-0'
                  )}
                >
                  <div className="flex h-full min-h-full w-full flex-col">
                    <ErrorBoundary key={router.asPath}>
                      <div className="w-full flex-auto">{children}</div>
                    </ErrorBoundary>
                  </div>
                </div>
              </>
            )}
          </section>
        </main>

        <Modal
          size="medium"
          active={showModal}
          toggler={() => {
            dispatch(toggleModal())
          }}
        >
          {(showModal && modalContent) || null}
        </Modal>
      </div>
      {!loaded && <FullPageLoader />}
    </>
  )
}
