import React, { PropsWithChildren, useEffect, useState } from 'react'
import { IconButton, Icons } from '@elements/icons/icon'
import { classNames } from '@utils/helpers/classNameHelper'

export const ModalSizes = {
  small: 'max-w-sm',
  smallMedium: 'max-w-xl',
  medium: 'max-w-3xl',
  large: 'max-w-6xl rounded-xl shadow-xl',
}

export type ModalSizeTypes = keyof typeof ModalSizes

const variantStyles = {
  normal: 'w-auto my-6 mx-auto ${size}',
  'full-screen': 'absolute h-full w-full',
}

export interface ModalProps {
  testid?: string
  size?: ModalSizeTypes
  active?: boolean
  toggler?: () => any
  quickDismiss?: boolean
  className?: string
  variant?: 'normal' | 'full-screen'
  modalTitle?: string
  cleanup?: () => void
}

export const Modal = ({
  children,
  testid = '',
  size = 'small',
  active = false,
  toggler = () => {},
  quickDismiss = true,
  className = '',
  variant = 'normal',
  modalTitle = '',
  cleanup,
}: PropsWithChildren<ModalProps>) => {
  useModalScrollLocker(active)

  useEffect(() => {
    if (!active && cleanup) {
      return cleanup
    }
  }, [active])

  return (
    <div
      className={classNames('fixed z-[60]', active ? 'visible pointer-events-auto' : 'invisible pointer-events-none')}
      role="dialog"
      aria-labelledby="Modal"
      aria-describedby="Sample description"
    >
      <div
        className={classNames('flex items-center fixed inset-0 z-50 overflow-auto')}
        onClick={quickDismiss ? toggler : undefined}
        data-testid={testid}
      >
        <div
          className={classNames(
            `transition-all duration-200 transform ease-in-out p-2`,
            variantStyles[variant],
            active ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          )}
        >
          {children && (
            <div
              className={classNames(
                `border-0 p-6 flex flex-col w-full bg-white overflow-y-auto rounded-lg shadow-lg overscroll-contain`,
                variant === 'full-screen' ? 'pt-20 h-full rounded-none' : 'pt-16 max-h-padded-screen',
                ModalSizes[size],
                className
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="fixed top-5 right-6 z-10 self-end">
                <IconButton callback={toggler} asset={Icons.cancel} className="bg-white text-secondary" />
              </div>
              <h6 className="flex justify-center">{modalTitle}</h6>
              <div className="relative flex-auto">{children}</div>
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames(
          active ? 'opacity-100' : 'opacity-0',
          'fixed inset-0 z-40 backdrop-blur-lg backdrop-saturate-180 bg-black/40 ease-in-out transition-all duration-300'
        )}
      />
    </div>
  )
}

const useModalScrollLocker = (active: boolean) => {
  const [bodyElement, setBodyElement] = useState<HTMLBodyElement | null>(null)
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0)
  useEffect(() => setBodyElement(document.querySelector('body')), [])
  useEffect(() => {
    if (active && bodyElement) {
      const scrollPos = window.scrollY
      setPreviousScrollPosition(scrollPos)
      bodyElement.style.position = 'fixed'
      bodyElement.style.top = `-${scrollPos}px`
      bodyElement.style.width = `100%`
    }
    if (!active && bodyElement) {
      bodyElement.style.position = 'unset'
      bodyElement.style.top = `unset`
      bodyElement.style.width = `unset`
      window.scrollTo(0, previousScrollPosition)
    }
  }, [active])
}
