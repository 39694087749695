import { SellChecklistStatus } from '@home-in/models'
import {
  WaratahPropertyWithChecklistNoTasks,
  WaratahPropertyWithChecklist,
  WaratahTaskWithInfo,
} from '@features/checklist/data/checklist-data-types'
import { SellChecklist } from '@redux/reducers/sell'

export const getTasksFromChecklist = (checklist: WaratahPropertyWithChecklistNoTasks) =>
  checklist?.checklist?.flatMap((milestone) => milestone.categories.flatMap((category) => category.tasks))

/**
 * This function is used to get the user's progress for their checklist for buy side
 * @param checklist
 * @returns completedItems, totalItems, progress
 */
export const getChecklistProgressBuySide = (checklist: WaratahPropertyWithChecklist | null | undefined) => {
  const eligibleInProgressItems = (checklist?.tasks as WaratahTaskWithInfo[]).filter((item) => item.title)
  const totalItems = eligibleInProgressItems?.length || 0
  const completedItems = eligibleInProgressItems?.filter((task) => task.completed === true).length || 0

  return { completedItems, totalItems, progress: (completedItems / totalItems) * 100 }
}

/**
 * This function is used to get the user's progress for their checklist for sell side
 * @param checklist
 * @returns completedItems, totalItems, progress
 */
export const getChecklistProgressSellSide = (checklist: SellChecklist | null | undefined) => {
  const tasks = checklist?.tasks
  const totalItems = tasks?.length || 0
  const completedItems = tasks?.filter((task) => task.status === SellChecklistStatus.COMPLETED).length || 0

  return { completedItems, totalItems, progress: (completedItems / totalItems) * 100 }
}
