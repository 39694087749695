import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useDebouncedRedirect = ({
  redirect,
  condition,
  dependencies,
}: {
  redirect: string
  condition: any
  dependencies: any[]
}) => {
  const router = useRouter()

  useEffect(() => {
    if (router.isReady && !!condition && !router.asPath.includes(redirect)) {
      router.push(redirect)
    }
  }, [router, ...dependencies])
}
