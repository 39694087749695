import { Actor, ChecklistItemSchemaType, WaratahRecordSchemaType, TaskType } from '@home-in/models'
import { Illustrations } from '@elements/icons/illustration'

export enum MilestoneIds {
  findProperty = 'find-property',
  getProperty = 'get-property',
  ownProperty = 'own-property',
  sellProperty = 'sell-property',
}

export interface MilestonesInfo {
  id: MilestoneIds
  title: string
  subtitle: string
  icon: Illustrations
  checklistSortOrder: number
}

export enum CategoryIds {
  financeAndOwnership = 'finance-and-ownership',
  propertyChecks = 'property-checks',
  contractReview = 'contract-review',
  auction = 'auction',
  propertyInsurance = 'property-insurance',
  homeLoan = 'home-loan',
  prioritySettlementTasks = 'priority-settlement-tasks',
  movePlanning = 'move-planning',
  settlement = 'settlement',
  settlementFunds = 'settlement-funds',
  offerAndSigning = 'offer-and-signing',
  coolingOffPeriod = 'cooling-off-period',
  conditionalContractTerms = 'conditional-contract-terms',
  sell = 'sell',
}

export const categorySlugToId: Record<CategoryIds, string> = {
  [CategoryIds.financeAndOwnership]: 'CAT0001',
  [CategoryIds.propertyChecks]: 'CAT0002',
  [CategoryIds.contractReview]: 'CAT0003',
  [CategoryIds.auction]: 'CAT0004',
  [CategoryIds.propertyInsurance]: 'CAT0005',
  [CategoryIds.homeLoan]: 'CAT0006',
  [CategoryIds.prioritySettlementTasks]: 'CAT0007',
  [CategoryIds.movePlanning]: 'CAT0008',
  [CategoryIds.settlement]: 'CAT0009',
  [CategoryIds.settlementFunds]: 'CAT0010',
  [CategoryIds.offerAndSigning]: 'CAT0011',
  [CategoryIds.coolingOffPeriod]: 'CAT0012',
  [CategoryIds.conditionalContractTerms]: 'CAT0013',
  [CategoryIds.sell]: 'CAT0014',
} as const

export interface CategoryInfo {
  id: CategoryIds
  title: string
  milestoneId: MilestoneIds
  milestoneSortOrder: number
}

export interface TaskInfo {
  title: string
  categoryId: CategoryIds
  categorySortOrder: number
  taskIds: string[]
  actor: Actor
  guidanceArticles: string[]
  type: TaskType
  staticTaskReference: string
  dynamicData?: boolean
}

export interface WaratahTaskWithInfo
  extends Omit<TaskInfo, 'taskIds' | 'staticTaskReference'>,
    ChecklistItemSchemaType {}
export interface WaratahCategory extends CategoryInfo {
  tasks: WaratahTaskWithInfo[]
}

export type WaratahCategoryNoTasks = Omit<WaratahCategory, 'tasks'>

export interface WaratahMilestone extends MilestonesInfo {
  categories: WaratahCategory[]
}

export interface WaratahMilestoneWithoutTasks extends MilestonesInfo {
  categories?: WaratahCategoryNoTasks[]
}

export interface WaratahPropertyWithChecklistNoTasks extends Omit<WaratahRecordSchemaType, 'checklist'> {
  checklist: WaratahMilestone[]
}

export interface WaratahPropertyWithChecklist extends Omit<WaratahRecordSchemaType, 'checklist'> {
  checklist: WaratahMilestone[]
  tasks: WaratahTaskWithInfo[]
}
