import {
  ChecklistItemSchemaType,
  ErrorSeverity,
  SELL_SIDE_TASKS,
  SellChecklistTaskId,
  SellChecklistTaskMetadata,
  SellSideChecklistItemType,
  State,
} from '@home-in/models'
import {
  MilestoneIds,
  WaratahCategory,
  WaratahMilestone,
  WaratahMilestoneWithoutTasks,
  WaratahTaskWithInfo,
} from '@features/checklist/data/checklist-data-types'
import { milestonesAndCategories } from '@features/checklist/data/milestone-and-category-content'
import { taskInformation } from '@features/checklist/data/task-content'
import { sendClientLog } from '@utils/helpers/error.helpers'

export const buildChecklist = ({
  tasks,
  contractSigned,
  state,
}: {
  tasks: ChecklistItemSchemaType[]
  contractSigned: boolean
  state: State
}): WaratahMilestone[] => {
  if (!tasks.length) return []

  return (
    milestonesAndCategories(contractSigned)
      .map((milestone) => addTasksAndCategoriesToMilestones(milestone, tasks, contractSigned, state))
      .sort((a, b) => a.checklistSortOrder - b.checklistSortOrder)
      // If the milestone is 'own property' it always needs to shown even if it has no categories
      .filter((milestone) => milestone.id === MilestoneIds.ownProperty || !!milestone.categories?.length)
  )
}

const addTasksAndCategoriesToMilestones = (
  milestone: WaratahMilestoneWithoutTasks,
  tasks: ChecklistItemSchemaType[],
  contractSigned: boolean,
  state: State
): WaratahMilestone => ({
  ...milestone,
  categories: (milestone?.categories || [])
    .sort((a, b) => a.milestoneSortOrder - b.milestoneSortOrder)
    .map(
      (category): WaratahCategory => ({
        ...category,
        // add our tasks to the category conditionally based on if they exist in the Waratah response
        tasks: taskInformation({ contractSigned, state })
          .filter((taskInfo) => taskInfo.categoryId === category.id)
          .map((taskInfo): WaratahTaskWithInfo => {
            const waratahTask = tasks.find((waratahTask) => taskInfo.taskIds.includes(waratahTask.taskId))

            return {
              title: taskInfo.title,
              taskId: waratahTask?.taskId || '',
              taskRef: waratahTask?.taskRef || '',
              categoryId: taskInfo.categoryId,
              actor: taskInfo.actor,
              categorySortOrder: taskInfo.categorySortOrder,
              enabled: waratahTask?.enabled || false,
              completed: waratahTask?.completed || false,
              guidanceArticles: taskInfo.guidanceArticles,
              type: taskInfo.type,
            }
          })
          .filter((task) => task.taskId !== '')
          .sort((a, b) => a.categorySortOrder - b.categorySortOrder),
      })
    )
    .filter((category) => !!category.tasks?.length),
})

// Legacy task Ids that are not added for new properties. Only used to cater in-flight cases.
const legacyTaskIds = ['ACT0030', 'ACT0217', 'ACT0106']

export const checkAllTasksAreIncluded = (checklist: WaratahMilestone[], waratahTasks: ChecklistItemSchemaType[]) => {
  // BEL-1647 check task ids returned from Waratah
  // Ensure all task ids from the waratah API response are present in the
  // buildChecklist() output
  const flattendedTaskIds = checklist
    .map((milestone) => milestone.categories.map((category) => category.tasks.map((task) => task.taskId)).flat())
    .flat()
  const taskIds = waratahTasks.filter((waratahTask) => !flattendedTaskIds.includes(waratahTask.taskId))

  // Do not check if legacy tasks are included.
  // These tasks should not be included within the built checklist
  // They are not added in new properties but exist as tasks in the legacy checklists for older customers
  const allValidTaskIds = taskIds.filter((waratahTask) => !legacyTaskIds.includes(waratahTask.taskId))

  if (allValidTaskIds.length > 0) {
    const taskIdsStr = taskIds
      .map((waratahTask) => waratahTask.taskId)
      .sort()
      .join(', ')
    sendClientLog({
      message: `Unknown taskIds in Waratah API response: ${taskIdsStr}`,
      severity: ErrorSeverity.ERROR,
    })
  }
}

// Checks for duplicate tasks within Waratah checklist and logs the task ids as an error
export const checkForDuplicateTasks = (checklist: ChecklistItemSchemaType[]) => {
  let taskIdTracker: { [taskId: string]: number } = {}

  checklist.forEach(({ taskId }) => {
    if (taskIdTracker[taskId] === undefined) {
      taskIdTracker[taskId] = 1
    } else {
      taskIdTracker[taskId]++
    }
  })

  let taskIdsList = ''

  Object.entries(taskIdTracker).forEach((task) => {
    if (task[1] > 1) {
      taskIdsList = taskIdsList + ` ${task[0]} (${task[1]}),`
    }
  })

  taskIdsList = taskIdsList.slice(0, -1)

  if (taskIdsList) {
    // Ticket to fix: https://home-in.atlassian.net/browse/BEL-1785
    sendClientLog({
      message: `Checklist task duplicates in Waratah API response after buildChecklist:${taskIdsList}`,
      severity: ErrorSeverity.WARNING,
    })
  }
}

export interface SellSideTaskWithMetadata extends SellChecklistTaskMetadata, SellSideChecklistItemType {}

// Note: Sell side is much simpler so we have reduced complexity
// There are no milestones and categories all locking up tasks
// We just have a heading (that looks like a milestone) then we
// show all tasks underneath.
// This function just adds the task metadata to tasks for the UI
export const buildTasksForSellSide = ({
  sellChecklistItems,
}: {
  sellChecklistItems: SellSideChecklistItemType[]
}): SellSideTaskWithMetadata[] =>
  sellChecklistItems.map((task) => {
    const taskId = task.taskId as SellChecklistTaskId
    const taskMeta = SELL_SIDE_TASKS?.[taskId]
    return { ...task, ...taskMeta }
  })
