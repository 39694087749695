import { GetPricingRequest, GetPricingResponse } from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithHeaders } from '@redux/apis/custom-base-query'

export const pricingApi = createApi({
  reducerPath: 'pricingApi',
  baseQuery: baseQueryWithHeaders('eagle/v1/'),
  endpoints: (builder) => ({
    getPrice: builder.query<GetPricingResponse, GetPricingRequest>({
      query: (params) => ({
        url: `pricing`,
        params,
      }),
      transformResponse: (response: { data: GetPricingResponse }) => response?.data,
      keepUnusedDataFor: 60,
    }),
  }),
})

export const { useGetPriceQuery, useLazyGetPriceQuery } = pricingApi
