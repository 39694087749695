import LinearProgress from '@mui/material/LinearProgress'
import React, { FC } from 'react'

export const ProgressBar: FC<{ progress: number; overrideBgColor?: boolean }> = ({
  progress = 1,
  overrideBgColor = true,
}) => {
  return (
    <LinearProgress
      className="w-full"
      variant="determinate"
      value={progress}
      style={{ ...(overrideBgColor && { backgroundColor: '#dddddd' }) }}
    />
  )
}
