import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAppDispatch } from '@redux/hooks'
import { updatePage } from '@redux/reducers/page'

export const useRouteChange = ({ pageTitle }: { pageTitle: string }) => {
  const router = useRouter()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updatePage({ title: pageTitle, route: router.asPath }))
  }, [router.asPath, pageTitle])
}
