import { RadioCardInput } from '@elements/forms/radio-cards'

const filterEnumToStringArray = (enumerable: object): string[] => {
  if (!enumerable || typeof enumerable !== 'object') {
    return []
  }
  return Object.values(enumerable).filter((value) => typeof value === 'string')
}

const enumToSelectOptions = (enumerable: object) => {
  return Object.keys(enumerable)
    .filter((key) => typeof (enumerable as any)[key] === 'string')
    .map((key) => {
      const value = (enumerable as any)[key]
      return {
        value: typeof value !== 'string' ? key : value,
        name: value,
      }
    })
}

const getEnumKeyByEnumValue = (myEnum: any, enumValue: number | string): string => {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue)
  return keys.length > 0 ? keys[0] : ''
}

const filterEnumToRadioCardInputs = (enumerable: object): RadioCardInput[] => {
  if (!enumerable || typeof enumerable !== 'object') {
    return []
  }

  return Object.values(enumerable).map((value) => ({ input: value }))
}

export { enumToSelectOptions, filterEnumToStringArray, getEnumKeyByEnumValue, filterEnumToRadioCardInputs }
