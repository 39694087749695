import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useRef } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useMobileQuery } from '@hooks/layoutHooks'
import { useAppSelector } from '@redux/hooks'

const useLenderIntercomSetup = () => {
  const intercom = useIntercom()
  const lenderProfile = useAppSelector((state) => state.lenderProfile)
  const user = useAppSelector((state) => state.auth.user)
  const booted = useRef(false)
  const isMobile = useMobileQuery()
  const { denyLender } = useFlags()

  useEffect(() => {
    if (!booted.current && lenderProfile && lenderProfile?.email && !denyLender) {
      intercom.boot({
        name: lenderProfile.firstName + ' ' + lenderProfile.lastName,
        phone: lenderProfile.phone,
        email: lenderProfile.email,
        userId: user?.sub,
        userHash: lenderProfile?.chatKey,
        hideDefaultLauncher: isMobile,
      })
      booted.current = true
    }
  }, [lenderProfile, denyLender])

  useEffect(() => {
    const buyingAssistantName = `${lenderProfile?.buyingAssistant?.firstName} ${lenderProfile.buyingAssistant?.lastName}`

    // Update the user's Intercom profile with BA and Conveyancer information
    // Intercom uses title case keys for custom attributes
    if (booted.current && buyingAssistantName) {
      intercom.update({
        hideDefaultLauncher: isMobile,
        customAttributes: {
          ...(!!buyingAssistantName ? { 'Buying Assistant': buyingAssistantName } : {}),
        },
      })
    }
  }, [booted.current, isMobile])
}

export default useLenderIntercomSetup
