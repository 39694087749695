import Head from 'next/head'
import Image from 'next/image'
import React, { useEffect } from 'react'
import { Card } from '@elements/card'
import Logo from '@elements/logo'
import { useAppDispatch } from '@redux/hooks'
import { trackPageView } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'

export const MaintenancePage = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      trackPageView({
        action: 'Maintenance',
        category: AnalyticsCategories.PageView,
      })
    )
  }, [])

  return (
    <>
      <Head>
        <title>Maintenance | Home-in</title>
      </Head>
      <div className="flex min-h-screen flex-col justify-center bg-gradient-to-tr from-primary to-secondary align-middle">
        <Image
          alt="panda"
          src="/images/peeking-panda.png"
          height="50"
          width="50"
          objectFit="contain"
          unoptimized
          className="mx-auto"
        />
        <Card className="w-11/12 max-w-4xl self-center rounded-lg bg-white/90 py-16 px-6 text-center shadow-xl backdrop-blur-xl backdrop-saturate-200 md:px-24 md:py-16">
          <Logo className="mb-10" />
          <p className="mb-2 text-muted">
            We are making some improvements to your Home-in experience. The app will be unavailable while we work hard
            on it.
          </p>
          <p className="text-muted">We apologise for any inconvenience and thank you for your understanding.</p>
        </Card>
      </div>
    </>
  )
}
