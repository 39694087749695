import { User } from '@auth0/auth0-react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  user: User | null
  loaded?: boolean
}

export const auth = createSlice({
  name: 'auth',
  initialState: <UserState>{
    user: null,
    loaded: false,
  },
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload }
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload
    },
  },
})

export const { setLoaded, setUser } = auth.actions
export default auth.reducer
