import { jwtDecode } from 'jwt-decode'
import { useEffect } from 'react'
import { dispatchDataToMammoth } from '@helpers/app-data-bridge'
import { useAppDispatch } from '@redux/hooks'
import { setUserIdentifier } from '@redux/reducers/analytics'
import { setUser } from '@redux/reducers/auth'
import { GetAccessTokenSilentlyFunc, GetIdTokenClaimsFunc, jwt } from '@utils/helpers/jwt.helpers'
import { parseStorageItem } from '@utils/storage'
import { useMobileAppAuth } from './deviceHook'

/**
 * Hook to override auth0 methods/variables user, getAccessTokenSilently & getIdTokenClaims in native app context
 */
export const useSetupAccessTokensForReactNative = () => {
  const dispatch = useAppDispatch()
  const mobileAppAuthRequired = useMobileAppAuth()

  useEffect(() => {
    if (mobileAppAuthRequired) {
      const getAccessTokenSilently: GetAccessTokenSilentlyFunc = async () => {
        var dateNow = new Date()
        const token = parseStorageItem('token')
        if (!token?.expiresAt || token?.expiresAt < dateNow.getTime() / 1000) {
          dispatchDataToMammoth(JSON.stringify({ action: 'onLogout', args: ['Expired token'] }))
          return
        } else {
          return token?.accessToken
        }
      }
      jwt.setAccessTokenSilently(getAccessTokenSilently)

      const getIdTokenClaims: GetIdTokenClaimsFunc = async () => {
        return jwtDecode(parseStorageItem('token')?.idToken)
      }
      jwt.setIdTokenClaims(getIdTokenClaims)

      const user: any = parseStorageItem('user')
      dispatch(setUser({ user }))
      dispatch(setUserIdentifier(user?.sub))
    }
  }, [])
}
