import { User } from '@auth0/auth0-react'

export const AccessGroup = {
  Lenders: 'LENDERS',
}

/**
 * This helper function determines whether the user is a Lender from the user state
 * in the redux store. The access_group property is originally injected in an
 * auth0 action by reading from app_metadata (an auth0 construct).
 * @param user
 * @returns true if user is a lender
 */
export const isLender = (user: User | null) => {
  return user && user?.access_group === AccessGroup.Lenders
}
