import { LenderCustomer, LenderCustomerProperty } from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithHeaders } from './custom-base-query'

export interface Lender {
  name: string
  email: string
}

export type LenderResponse = Lender[]

export type LenderCustomerJourneyResponse = {
  customerId: string
  firstName: string
  middleName: string
  lastName: string
  phone: string
  email: string
  //FIXME: based on updated response
  properties: any[]
}

export const lenderApi = createApi({
  reducerPath: 'lenderApi',
  baseQuery: baseQueryWithHeaders('lorikeet/v1/'),
  endpoints: (builder) => ({
    getLenders: builder.query<LenderResponse, void>({
      query: () => 'lenders/cba',
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: { lenders: LenderResponse } }) => response?.data.lenders,
    }),
  }),
})

export const lenderCustomersApi = createApi({
  reducerPath: 'lenderCustomersApi',
  baseQuery: baseQueryWithHeaders('hamster/v1/'),
  endpoints: (builder) => ({
    getLenderCustomers: builder.query<LenderCustomer[], void>({
      query: () => 'lender/customers',
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: { customers: LenderCustomer[] } }) => response?.data.customers,
    }),
  }),
})

export const lenderCustomerJourneyApi = createApi({
  reducerPath: 'lenderCustomerJourneyApi',
  baseQuery: baseQueryWithHeaders('hamster/v1/'),
  endpoints: (builder) => ({
    getLenderCustomerJourney: builder.query<LenderCustomerJourneyResponse, string>({
      query: (customerId) => `lender/customers/${customerId}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: LenderCustomerJourneyResponse }) => response?.data,
    }),
  }),
})

export const { useGetLendersQuery } = lenderApi
export const { useGetLenderCustomersQuery } = lenderCustomersApi
export const { useGetLenderCustomerJourneyQuery, useLazyGetLenderCustomerJourneyQuery } = lenderCustomerJourneyApi
