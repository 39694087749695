import { UploadDocumentResponseType, UploadDocumentRequestTypeV2 } from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/query/react'
import { DownloadDocumentResponse, S3FileMetadata } from '@redux/utils/documentsv2'
import { baseQueryWithHeaders } from './custom-base-query'

export const documentsApiV2 = createApi({
  reducerPath: 'documentsApiV2',
  baseQuery: baseQueryWithHeaders('camel/v2/'),
  tagTypes: ['DocumentsV2'],
  endpoints: (builder) => ({
    getSignedUrlV2: builder.mutation<string, UploadDocumentRequestTypeV2>({
      query: (body) => ({ url: 'documents/upload-presigned-url', method: 'POST', body }),
      transformResponse: (response: { data: UploadDocumentResponseType }) => response.data.preSignedUrl,
    }),
    getFileDownloadUrlV2: builder.query<string, { s3Key: string }>({
      query: (queryStringParams) => ({
        url: `documents/storage?storagePath=${encodeURIComponent(queryStringParams.s3Key)}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: UploadDocumentResponseType }) => response.data.preSignedUrl,
    }),
    getDocumentListV2: builder.query<S3FileMetadata[], undefined>({
      query: () => 'documents/list',
      providesTags: ['DocumentsV2'],
      keepUnusedDataFor: 5,
      transformResponse: (response: DownloadDocumentResponse): S3FileMetadata[] => {
        return response.data.files
      },
    }),
  }),
})

export const {
  useGetSignedUrlV2Mutation,
  useGetDocumentListV2Query,
  useGetFileDownloadUrlV2Query,
  useLazyGetFileDownloadUrlV2Query,
  useLazyGetDocumentListV2Query,
} = documentsApiV2

type PostUnscannedFileMetadata = {
  preSignedUrls: string[]
}

export const unscannedFileMetadata = createApi({
  reducerPath: 'unscannedFileMetadata',
  baseQuery: baseQueryWithHeaders('camel/v1/'),
  tagTypes: ['Documents'],
  endpoints: (builder) => ({
    postUnscannedFileMetadata: builder.mutation<string, PostUnscannedFileMetadata>({
      query: (body) => ({ url: 'upload-file-metadata', method: 'POST', body }),
    }),
  }),
})

export const { usePostUnscannedFileMetadataMutation } = unscannedFileMetadata
