import { checklistApi } from '@redux/apis/checklist'
import { homeApi } from '@redux/apis/home'
import { lenderProfileApi } from '@redux/apis/lender-profile'
import { lenderCustomerJourneyApi, lenderCustomersApi } from '@redux/apis/lenders'
import { profileApi } from '@redux/apis/profile'
import { sellChecklistApi } from '@redux/apis/sell-checklist'
import { sellPropertyApi } from '@redux/apis/sell-property'
import { rtkQueryToStore } from '@redux/listeners'
import { setChecklists } from '@redux/reducers/checklist'
import { setWorkParty, setHomeBuyingJourneys } from '@redux/reducers/home'
import { setLenderCustomerJourney } from '@redux/reducers/lender-customerjourney'
import { setLenderCustomers } from '@redux/reducers/lender-customers'
import { setLenderProfile } from '@redux/reducers/lender-profile'
import { setProfile } from '@redux/reducers/profile'
import { setSellChecklists, setSellProperties } from '@redux/reducers/sell'

export const apiListeners = () => {
  /**
   * Put any side effects here where you want to map the latest value of an RTK query event to the store
   */
  rtkQueryToStore(profileApi.endpoints.getProfile.matchFulfilled, setProfile)
  rtkQueryToStore(homeApi.endpoints.getHomeBuyingJourney.matchFulfilled, setHomeBuyingJourneys)
  rtkQueryToStore(checklistApi.endpoints.getChecklist.matchFulfilled, setChecklists)
  rtkQueryToStore(homeApi.endpoints.getTeam.matchFulfilled, setWorkParty)

  rtkQueryToStore(sellPropertyApi.endpoints.getSellProperties.matchFulfilled, setSellProperties)
  rtkQueryToStore(sellChecklistApi.endpoints.getAllSellChecklists.matchFulfilled, setSellChecklists)
  rtkQueryToStore(lenderProfileApi.endpoints.getLenderProfile.matchFulfilled, setLenderProfile)
  rtkQueryToStore(lenderCustomersApi.endpoints.getLenderCustomers.matchFulfilled, setLenderCustomers)
  rtkQueryToStore(lenderCustomerJourneyApi.endpoints.getLenderCustomerJourney.matchFulfilled, setLenderCustomerJourney)
}
