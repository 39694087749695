import { LenderCustomer } from '@home-in/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const lenderCustomers = createSlice({
  name: 'lenderCustomers',
  initialState: <LenderCustomer[]>[],
  reducers: {
    setLenderCustomers: (state, action: PayloadAction<LenderCustomer[]>) => {
      return action.payload || []
    },
  },
})

export const { setLenderCustomers } = lenderCustomers.actions
export default lenderCustomers.reducer
