import { useRouter } from 'next/router'
import React from 'react'
import { IconButton, Icons } from '@elements/icons/icon'

export enum ActionButtonEnum {
  AddProperty,
  Refresh,
}

interface ActionButtonProps {
  action?: ActionButtonEnum
  actionLink?: string
}

export const ActionButton = ({ action, actionLink = '/' }: ActionButtonProps) => {
  const router = useRouter()

  switch (action) {
    case ActionButtonEnum.AddProperty:
      return (
        <div>
          <div className="hidden sm:block">
            <IconButton
              className="inline-flex text-secondary"
              asset={Icons.add}
              callback={() => router.push(actionLink)}
              text="Add Property"
            />
          </div>
          <div className="block sm:hidden">
            <IconButton
              className="inline-flex text-secondary"
              asset={Icons.add}
              callback={() => router.push(actionLink)}
            />
          </div>
        </div>
      )
    case ActionButtonEnum.Refresh:
      return (
        <IconButton
          className="inline-flex text-secondary"
          asset={Icons.refresh}
          callback={() => router.push(router.asPath)}
        />
      )
    default:
      return <div className="box-content w-4 p-2.5" />
  }
}
