import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithHeaders } from './custom-base-query'
import type {
  StorePreSignupDataRequestType,
  StorePreSignupDataResponseType,
  GetPreSignupDataResponseType,
} from '@home-in/models'

export const acquisitionFunnelApi = createApi({
  reducerPath: 'acquisitionFunnelApi',
  baseQuery: baseQueryWithHeaders('meerkat/v1/'),
  endpoints: (builder) => ({
    storePreSignupData: builder.mutation<StorePreSignupDataResponseType, StorePreSignupDataRequestType>({
      query: (body) => ({ url: 'pre-signup-data', method: 'POST', body }),
      transformResponse: (response: { data: StorePreSignupDataResponseType }) => response?.data,
    }),
    getPreSignupData: builder.query<GetPreSignupDataResponseType, void>({
      query: () => 'pre-signup-data',
      transformResponse: (response: { data: GetPreSignupDataResponseType }) => response?.data,
    }),
  }),
})

export const { useStorePreSignupDataMutation, useGetPreSignupDataQuery } = acquisitionFunnelApi
