import { ErrorSeverity } from '@home-in/models'
import { useEffect } from 'react'
import { useAppSelector } from '@redux/hooks'
import { sendClientLog } from '@utils/helpers/error.helpers'

export const useHandleUncaughtErrors = () => {
  const auth0id = useAppSelector((state) => state.auth.user?.sub)
  const route = useAppSelector((state) => state.page.route)

  const handleUncaughtWindowError = (event: ErrorEvent) => {
    sendClientLog({
      auth0id,
      host: window?.location?.host,
      message: `Uncaught error: ${event.error?.toString()}`,
      route,
      stackTrace: event.error?.stack,
      severity: ErrorSeverity.ERROR,
    })
  }

  const handleUncaughtPromiseRejection = (event: PromiseRejectionEvent) => {
    // Nicked from https://github.com/vercel/next.js/blob/canary/packages/react-dev-overlay/src/client.ts
    if (!event?.reason || !(event?.reason instanceof Error) || typeof event?.reason.stack !== 'string') {
      // A non-error was thrown, we don't have anything to show. :-(
      return
    }

    sendClientLog({
      auth0id,
      host: window?.location?.host,
      message: `Uncaught promise rejection: ${event.reason?.toString()}`,
      route,
      error: event.reason,
      stackTrace: event.reason.stack,
      severity: ErrorSeverity.ERROR,
    })
  }

  useEffect(() => {
    // In 'development' (running locally via `npm run start:dev`) the Next.js error handler will interfere
    if (process.env.NODE_ENV !== 'development') {
      window.addEventListener('error', handleUncaughtWindowError)
      window.addEventListener('unhandledrejection', handleUncaughtPromiseRejection)
    }

    return () => {
      window.removeEventListener('error', handleUncaughtWindowError)
      window.removeEventListener('unhandledrejection', handleUncaughtPromiseRejection)
    }
  }, [])
}
