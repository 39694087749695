import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import { Button } from '@elements/button'
import Logo from '@elements/logo'
import { classNames } from '@utils/helpers/classNameHelper'
import AcquisitionFunnelPublicWrapper from './acquisition-funnel-public-wrapper'

export interface PublicWrapperProps {
  hideNav?: boolean
  hideActionButtons?: boolean
  variant?: 'default' | 'funnel'
}

export const PublicWrapper = ({ children, variant = 'default', ...props }: PropsWithChildren<PublicWrapperProps>) => {
  return variant === 'funnel' ? (
    <AcquisitionFunnelPublicWrapper>{children}</AcquisitionFunnelPublicWrapper>
  ) : (
    <PublicWrapperInner {...props}>{children}</PublicWrapperInner>
  )
}

export const PublicWrapperInner = ({
  hideNav = false,
  hideActionButtons = false,
  children,
}: PropsWithChildren<PublicWrapperProps>) => {
  return (
    <main className="flex min-h-screen flex-col" id="public-page-wrapper">
      {!hideNav && (
        <nav className="fixed z-50 mb-16 flex h-20 w-full items-center justify-between border-b border-b-background-dark-100 bg-light px-4 md:px-8">
          <a href={process.env['NEXT_PUBLIC_WEBFLOW_URL'] || '#'}>
            <Logo innerClassName="p-0 w-20 md:w-40 md:p-4" />
          </a>
          {!hideActionButtons && (
            <div className="pr-3">
              <Link href="/">
                <a>
                  <Button category="secondary" size="sm" className="py-2 px-3 text-xs md:py-3 md:text-lg">
                    Log in
                  </Button>
                </a>
              </Link>
              <Link href="/create-account">
                <a>
                  <Button
                    category="secondary_alt"
                    size="sm"
                    className="ml-2 py-2 px-3 text-xs md:ml-4 md:py-3 md:text-lg"
                  >
                    Sign up
                  </Button>
                </a>
              </Link>
            </div>
          )}
        </nav>
      )}
      <section className={classNames('flex min-h-screen', { 'pt-16': !hideNav })}>{children}</section>
    </main>
  )
}
