import { ThemeOptions } from '@mui/material'
import { blueGrey, yellow } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

const defaultThemeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: '#017f76',
      light: '#67b2ad',
      dark: '#014c47',
    },
    secondary: {
      main: '#0611FF',
      light: '#6a70ff',
      dark: '#040a99',
      contrastText: '#dae1e5',
    },
    text: {
      primary: '#00222b',
      secondary: '#476b7e',
    },
    error: {
      main: '#b71c1c',
      light: '#d47777',
      dark: '#6e1111',
    },
    success: {
      main: '#60d154',
      light: '#a0e398',
      dark: '#3a7d32',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          textTransform: 'none',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#d47777',
        },
      },
    },
  },
}

export const theme = createTheme(defaultThemeConfig)

const TestTheme = createTheme(
  deepmerge(defaultThemeConfig, {
    palette: {
      mode: 'dark',
      primary: {
        main: '#265422',
      },
      secondary: {
        main: yellow['A700'],
      },
      background: {
        default: blueGrey['800'],
        paper: blueGrey['700'],
      },
    },
  })
)

export const Themes = {
  DefaultTheme: theme,
  TestTheme,
}
