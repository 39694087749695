import { PromiseWithKnownReason } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types'
import { trackButtonClick, trackRequestFailure, trackRequestSuccess } from '@redux/reducers/analytics'
import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import type { AnalyticsEvent } from '@utils/helpers/analytics.interface'

interface TrackEventsInApiCall {
  dispatch: ThunkDispatch<any, any, AnyAction>
  queryFulfilled: PromiseWithKnownReason<any, any>
  event: AnalyticsEvent
  fulfilled?: (() => void) | null // extra things to do after queryFulfilled
  undo?: (() => void) | null // extra things to do if queryFulfilled fails
  callback?: (() => void) | null // always excute after the promise
}

// Use this inside the `onQueryStarted` function in an RTK Query API to track related events
export const trackEventsInApiCall = async ({
  dispatch,
  queryFulfilled,
  event,
  fulfilled = null,
  undo = null,
  callback = null,
}: TrackEventsInApiCall): Promise<void> => {
  dispatch(trackButtonClick(event))
  try {
    await queryFulfilled
    fulfilled?.()
    dispatch(trackRequestSuccess(event))
  } catch {
    undo?.()
    dispatch(trackRequestFailure(event))
  } finally {
    callback?.()
  }
}
