import { Illustrations } from '@elements/icons/illustration'
import { CategoryIds, MilestoneIds, WaratahMilestoneWithoutTasks } from '@features/checklist/data/checklist-data-types'

export const milestonesAndCategories = (contractSigned: boolean): WaratahMilestoneWithoutTasks[] => [
  {
    id: MilestoneIds.findProperty,
    title: "You've found a property!",
    subtitle: "Now it's time to make an offer",
    icon: Illustrations.homeTick,
    checklistSortOrder: 1,
    categories: [
      {
        id: CategoryIds.financeAndOwnership,
        title: 'Finance & Ownership',
        milestoneId: MilestoneIds.findProperty,
        milestoneSortOrder: 1,
      },
      {
        id: CategoryIds.propertyChecks,
        title: 'Property Checks',
        milestoneId: MilestoneIds.findProperty,
        milestoneSortOrder: 2,
      },
      {
        id: CategoryIds.auction,
        title: 'Auction',
        milestoneId: MilestoneIds.findProperty,
        milestoneSortOrder: 3,
      },
      {
        id: CategoryIds.offerAndSigning,
        title: 'Offer & Signing',
        milestoneId: MilestoneIds.findProperty,
        milestoneSortOrder: 4,
      },
      ...(!contractSigned
        ? [
            {
              id: CategoryIds.contractReview,
              title: 'Contract Review',
              milestoneId: MilestoneIds.findProperty,
              milestoneSortOrder: 2.5,
            },
            {
              id: CategoryIds.conditionalContractTerms,
              title: 'Conditional Contract Terms',
              milestoneId: MilestoneIds.findProperty,
              milestoneSortOrder: 5,
            },
          ]
        : []),
    ],
  },
  {
    id: MilestoneIds.getProperty,
    title: "You've got it!",
    subtitle: 'Terms negotiated & contract signed',
    icon: Illustrations.seal,
    checklistSortOrder: 2,
    categories: [
      ...(contractSigned
        ? [
            {
              id: CategoryIds.contractReview,
              title: 'Contract Review',
              milestoneId: MilestoneIds.getProperty,
              milestoneSortOrder: 0.5,
            },
            {
              id: CategoryIds.conditionalContractTerms,
              title: 'Conditional Contract Terms',
              milestoneId: MilestoneIds.getProperty,
              milestoneSortOrder: 2,
            },
          ]
        : []),
      {
        id: CategoryIds.coolingOffPeriod,
        title: 'Cooling Off Period',
        milestoneId: MilestoneIds.getProperty,
        milestoneSortOrder: 1,
      },
      {
        id: CategoryIds.prioritySettlementTasks,
        title: 'Priority Settlement Tasks',
        milestoneId: MilestoneIds.getProperty,
        milestoneSortOrder: 2,
      },
      {
        id: CategoryIds.homeLoan,
        title: 'Home Loan',
        milestoneId: MilestoneIds.getProperty,
        milestoneSortOrder: 3,
      },
      {
        id: CategoryIds.propertyInsurance,
        title: 'Property Insurance',
        milestoneId: MilestoneIds.getProperty,
        milestoneSortOrder: 4,
      },
      {
        id: CategoryIds.settlementFunds,
        title: 'Settlement Funds',
        milestoneId: MilestoneIds.getProperty,
        milestoneSortOrder: 5,
      },
      {
        id: CategoryIds.movePlanning,
        title: 'Move Planning',
        milestoneId: MilestoneIds.getProperty,
        milestoneSortOrder: 6,
      },
      {
        id: CategoryIds.settlement,
        title: 'Settlement',
        milestoneId: MilestoneIds.getProperty,
        milestoneSortOrder: 7,
      },
    ],
  },
  {
    id: MilestoneIds.ownProperty,
    title: 'You own it!',
    subtitle: 'Settlement completed successfully',
    icon: Illustrations.key,
    checklistSortOrder: 3,
  },
  {
    id: MilestoneIds.sellProperty,
    title: 'Sell property',
    subtitle: 'Sell',
    icon: Illustrations.key,
    checklistSortOrder: 1,
    categories: [
      {
        id: CategoryIds.sell,
        title: 'Sell',
        milestoneId: MilestoneIds.sellProperty,
        milestoneSortOrder: 1,
      },
    ],
  },
]
