import Link, { LinkProps } from 'next/link'
import React, { PropsWithChildren } from 'react'
import { classNames } from '@utils/helpers/classNameHelper'
const SUPPORT_EMAIL: string = process.env.NEXT_PUBLIC_SUPPORT_EMAIL!

type StyledLinkProps = PropsWithChildren<LinkProps> & { className?: string; target?: string; rel?: string }

type StyledLinkNoHref = Omit<StyledLinkProps, 'href'>

export const LinkStyled = ({ children, className, target, rel, ...props }: StyledLinkProps) => {
  return (
    <Link {...props}>
      <a
        className={classNames('text-secondary hover:text-secondary-variant underline duration-75', className)}
        target={target}
        rel={rel}
      >
        {children}
      </a>
    </Link>
  )
}

export const EmailLink = ({ address, children, ...props }: StyledLinkNoHref & { address: string }) => (
  <LinkStyled {...props} href={`mailto:${address}`}>
    {children || address}
  </LinkStyled>
)

export const SupportEmailLink = ({ ...props }: StyledLinkNoHref) => <EmailLink address={SUPPORT_EMAIL} {...props} />
