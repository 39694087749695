import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LenderState } from '@redux/apis/lender-profile'

export const lenderProfile = createSlice({
  name: 'lenderProfile',
  initialState: <Partial<LenderState>>{},
  reducers: {
    setLenderProfile: (state, action: PayloadAction<Partial<LenderState> | null>) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setLenderProfile } = lenderProfile.actions
export default lenderProfile.reducer
