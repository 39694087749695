import React from 'react'
import LoadingSpinner from '@elements/loading-spinner'

export const FullPageLoader = () => (
  <div className="w-full">
    <div className="flex max-h-full min-h-screen w-full flex-col justify-center bg-light align-middle">
      <div className="w-16 self-center">
        <LoadingSpinner className="h-16 w-16 text-center" />
      </div>
    </div>
  </div>
)
