import React, { useEffect } from 'react'

//must be inlined, this is what shows when all else has failed
const wrapperStyle: React.CSSProperties = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: '0px',
  left: '0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff',
  zIndex: 9999999,
}

const splitter: React.CSSProperties = {
  width: '140px',
  height: '1px',
  margin: '40px 0 30px 0',
  background: '#cccccc',
}

const h2: React.CSSProperties = {
  fontSize: '50px',
  lineHeight: '50px',
  color: '#525252',
  marginBottom: '15px',
}

const textWrapper: React.CSSProperties = {
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  textAlign: 'center',
}

export const FallbackError = () => {
  return (
    <div className="hidden">
      <div style={wrapperStyle}>
        <div
          dangerouslySetInnerHTML={{
            __html: `
        <img src="/logos/normal.svg" alt="Home-in" style="width: 250px" />
        `,
          }}
        ></div>
        <div style={splitter}></div>
        <div style={textWrapper}>
          <h2 style={h2}>Something went wrong</h2>
          <span>
            Press{' '}
            <a
              style={{ color: '#0e70e5', cursor: 'pointer', textDecoration: 'none' }}
              href={
                process.env.NEXT_PUBLIC_APP_ENV === 'production'
                  ? 'https://www.home-in.com.au/redirect-error-page'
                  : '/'
              }
            >
              here
            </a>{' '}
            to refresh
          </span>
        </div>
      </div>
    </div>
  )
}
