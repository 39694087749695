import Image from 'next/image'
import React from 'react'
import dark from '@public/logos/dark.svg'
import light from '@public/logos/light.svg'
import normal from '@public/logos/normal.svg'
import { classNames } from '@utils/helpers/classNameHelper'

export type LogoDesign = keyof typeof designs

interface LogoProps {
  design?: LogoDesign
  innerClassName?: string
  className?: string
}

const designs = {
  dark,
  light,
  normal,
}

const Logo = ({ design = 'normal', innerClassName = 'w-64', className }: LogoProps) => (
  <div className={classNames('flex justify-center', className)}>
    <div className={classNames(`p-4 text-center homein_logo`, innerClassName)}>
      <Image src={designs[design]} alt={`Home-in Logo ${design}`} sizes="fill" unoptimized />
    </div>
  </div>
)

export default Logo
