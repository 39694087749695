import { startAppListening } from '@redux/listeners'
import { setActiveHomeBuyingJourney, setHomeBuyingJourneys } from '@redux/reducers/home'

export const homeListeners = () => {
  startAppListening({
    actionCreator: setHomeBuyingJourneys,
    effect: async (action, api) => {
      const state = api.getState()

      // Force jump to the latest HBJ when a new one was created
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('jumpToLatestHbj')) {
        api.dispatch(setActiveHomeBuyingJourney(action.payload.hbj[0]?.hbj_id))
        return
      }

      if (!state.home.activeHbj) {
        api.dispatch(setActiveHomeBuyingJourney(action.payload.hbj[0]?.hbj_id))
      }

      if (state.home.activeHbj) {
        // Update the hbjId so it's not a cached result from another user
        const isHbjFromCurrentUser = action.payload.hbj.some(({ hbj_id }) => hbj_id === state.home.activeHbj?.hbj_id)
        if (!isHbjFromCurrentUser) {
          api.dispatch(setActiveHomeBuyingJourney(action.payload.hbj[0]?.hbj_id))
        }

        // Update the active HBJ if there are any changes
        const newActiveHbj = action.payload.hbj.find(({ hbj_id }) => hbj_id === state.home.activeHbj?.hbj_id)
        if (newActiveHbj && newActiveHbj !== state.home.activeHbj) {
          api.dispatch(setActiveHomeBuyingJourney(newActiveHbj.hbj_id))
        }
      }
    },
  })
}
