import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import useActiveWorkParty from '@hooks/useActiveWorkParty'
import { useAppSelector } from '@redux/hooks'

/**
 * This hook initializes the LaunchDarkly client with the user's buying assistant and conveyancer information
 */
const useLaunchDarklySetup = () => {
  const launchDarkly = useLDClient()
  const profile = useAppSelector((state) => state.profile)
  const { activeWorkParty } = useActiveWorkParty()

  useEffect(() => {
    const buyingAssistantName = activeWorkParty?.your_personal_contact?.full_name
    const conveyancingFirm = activeWorkParty?.partner_law_firm?.organisation

    // Update the user's LaunchDarkly context with BA and Conveyancing firm information
    if (!!profile.id && (!!buyingAssistantName || !!conveyancingFirm)) {
      launchDarkly?.identify({
        kind: 'user',
        key: profile?.id,
        email: profile?.email,
        conveyancingFirm: conveyancingFirm || '',
        buyingAssistant: buyingAssistantName || '',
      })
    }
  }, [activeWorkParty, profile?.id])
}

export default useLaunchDarklySetup
