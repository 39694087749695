import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReactElement } from 'react'

type ModalContent = null | JSX.Element | ReactElement

interface ModalStore {
  active: boolean
  content: ModalContent
  modalType: string
}

export const modal = createSlice({
  name: 'modal',
  initialState: <ModalStore>{
    active: false,
    content: null,
    modalType: '',
  },
  reducers: {
    disableModal: (state) => {
      state.active = false
    },
    enableModal: (state) => {
      state.active = true
    },
    toggleModal: (state) => {
      state.active = !state.active
    },
    setModalContent: (state, action: PayloadAction<ModalContent>) => {
      state.content = action.payload
    },
    setModalType: (state, action: PayloadAction<string>) => {
      state.modalType = action.payload
    },
  },
})

export const { disableModal, enableModal, setModalContent, toggleModal, setModalType } = modal.actions
export default modal.reducer
