const getClientId = (isLenderApp: boolean): string => {
  const lenderAppAuth0ClientId = process.env.NEXT_PUBLIC_AUTH0_LENDER_CLIENT_ID || ''
  const customerAppAuth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ''

  return isLenderApp ? lenderAppAuth0ClientId : customerAppAuth0ClientId
}

const getRedirectUri = (isLenderApp: boolean): string => {
  if (typeof window === 'undefined') return ''

  const originUrl = window?.location?.origin || ''

  return isLenderApp ? `${originUrl}/lender/customers` : originUrl
}

export { getClientId, getRedirectUri }
