import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'

// This hook is used to determine if the app is ready to load.
// It is used to prevent the app from loading until the LaunchDarkly client is ready.
export const useAppReadyToLoad = () => {
  const [ready, setReady] = useState(false)
  const launchDarkly = useLDClient()

  useEffect(() => {
    if (!launchDarkly) return
    ;(async () => {
      await launchDarkly.waitUntilReady()
      setReady(true)
    })()
  }, [launchDarkly])

  return ready
}
