import { useAuth0 } from '@auth0/auth0-react'
import { TransactionType } from '@home-in/models'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { Button } from '@elements/button'
import { Footer } from '@elements/footer'
import Logo from '@elements/logo'
import { ProgressBar } from '@elements/progress-bar'
import { FunnelPageSlug as Slugs } from '@features/acquisition-funnel/data/types'
import { isUtmUnloan, useGetUtmId } from '@hooks/useGetUtmId'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setCurrentFlow, setTransactionType } from '@redux/reducers/acquisition-funnel'
import { trackButtonClick } from '@redux/reducers/analytics'
import { setUtmId } from '@redux/reducers/app'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'

const AcquisitionFunnelPublicWrapper = ({ children }: PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch()
  const {
    query: { utm_id = '' },
  } = useRouter()

  useEffect(() => {
    if (utm_id) {
      dispatch(setUtmId(utm_id as string))
    }
  }, [utm_id])

  return <FunnelInner>{children}</FunnelInner>
}

const FunnelInner: React.FC = ({ children }) => {
  const { currentFlow, transactionType } = useAppSelector((state) => state.acquisitionFunnel)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { loginWithRedirect } = useAuth0()
  const utmId = useGetUtmId({ source: 'store' })

  useEffect(() => {
    if (transactionType) return
    if (isUtmUnloan(utmId)) {
      dispatch(
        setCurrentFlow([
          Slugs.GET_STARTED,
          Slugs.HAVE_YOU_FOUND_THE_PROPERTY,
          Slugs.STATE_OF_INTEREST,
          Slugs.HOW_SOON_LOOKING_TO_BUY,
          Slugs.PROPERTY_DETAILS,
          Slugs.TYPE_OF_SALE,
          Slugs.HAVE_COS,
          Slugs.COS_SIGNED,
          Slugs.AUCTION_DATE,
          Slugs.HOME_IN_CVP,
        ])
      )
      dispatch(setTransactionType(TransactionType.Buying))
    } else if (router.pathname !== '/get-started') {
      // Redirect to landing page if user refreshes in the middle of the flow
      router.push('/get-started')
    }
  }, [transactionType, utmId])

  const progress = useMemo(() => {
    if (!currentFlow) {
      return 0
    }

    const totalSteps = currentFlow.length
    const currentStep = currentFlow.findIndex((step) => `/get-started/${step}` === router.asPath)
    return Math.floor(((currentStep + 1) / totalSteps) * 100)
  }, [currentFlow, router.pathname])

  const signupCallback = () => {
    dispatch(
      trackButtonClick({
        action: `Pre sign-up | Sign up`,
        category: AnalyticsCategories.AcquisitionFunnel,
        value: (router.query?.slug as string) || 'get-started',
        transactionType,
      })
    )
    const params = {
      screen_hint: 'signup',
      ...(utmId && { 'ext-utm_id': utmId }),
    }

    loginWithRedirect(params)
  }

  return (
    <main className="flex min-h-screen w-full flex-col bg-white">
      <nav className="fixed z-50 mb-16 flex h-20 w-full justify-between border-b border-grey-400 bg-white">
        <div className="flex w-full flex-col">
          <div className="flex w-full items-center justify-between px-4 md:px-6">
            <a href={process.env['NEXT_PUBLIC_WEBFLOW_URL'] || '#'}>
              <Logo innerClassName="p-0 w-28 md:w-32" />
            </a>
            <div>
              <Button category="secondary" size="sm" className="py-4" overrideDefaultWidth callback={signupCallback}>
                Sign up
              </Button>
            </div>
          </div>
          {!!progress && <ProgressBar progress={progress} overrideBgColor={false} />}
        </div>
      </nav>
      <section className="max-w-screen relative flex flex-1 overflow-x-hidden pt-20">{children}</section>
      <Footer />
    </main>
  )
}

export default AcquisitionFunnelPublicWrapper
